import { ref, get, getDatabase } from 'firebase/database';

export const useHeartedOrFavorited = () => {
  const determineLikedStatus = async ({ senderId, receiverId }) => {
    if (!senderId || !receiverId) throw new Error('Invalid user id');

    const db = getDatabase();

    const userLikesRef = ref(db, `users/${senderId}/likes`);
    const userFavoritesRef = ref(db, `users/${senderId}/favorites`);

    const [likesSnapshotRef, favoritesSnapshotRef] = await Promise.all([
      get(userLikesRef),
      get(userFavoritesRef),
    ]);

    const likes = likesSnapshotRef.exists() ? likesSnapshotRef.val() : [];

    const favorites = favoritesSnapshotRef.exists()
      ? favoritesSnapshotRef.val()
      : [];

    const hasLikes = likes.includes(receiverId);
    const hasFavorites = favorites.includes(receiverId);

    const isLikedOrFavorited = hasLikes || hasFavorites;

    return isLikedOrFavorited;
  };

  return determineLikedStatus;
};
