const environment = process.env.REACT_APP_ENVIRONMENT;
const productionBackend = process.env.REACT_APP_BACKEND_URL_PRODUCTION;
const developmentBackend = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;

const determineBackendUrl = () => {
  if (environment === 'production') return productionBackend;
  if (environment === 'development') return developmentBackend;
};

export const backendUrl = determineBackendUrl();
