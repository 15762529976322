import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { api } from '../utils/api';
import useUserStore from '../hooks/useUserStore';
import { Link } from 'react-router-dom';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

function PaymentForm({ tier }) {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const { user } = useUserStore((state) => ({ user: state.user }));
  const userId = user.uid;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        setErrorMessage(error.message);
        return; // Exit the function early if there's an error
      }

      const url = `/create-subscription`;
      const data = {
        userId,
        tier,
        paymentMethodId: paymentMethod.id,
      };
      const call = { method: 'POST', url, data };
      const subscription = await api(call);

      const successfulPayment = subscription?.success;

      if (!successfulPayment) {
        setErrorMessage('Error processing the payment. Please try again.');
      }

      setSuccess(true);
    } catch (err) {
      console.error(err);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false); // Stop loading whether it was a success or failure
    }
  };

  return (
    <>
      {!success ? (
        <form onSubmit={handleSubmit} className="w-10/12 mx-auto">
          <fieldset className="FormGroup">
            <div className="FormRow">
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>
          <button className="button" disabled={loading}>
            {loading ? 'Processing...' : 'Pay'}
          </button>
          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        </form>
      ) : (
        <div className="mx-auto text-center w-[90%] text-[20px] md:text-[25px]">
          <h2>Membership successfully purchased!</h2>
          <Link to="/dashboard">
            <button className="bg-gray-200 text-[14px] py-1 rounded-md px-3 m-3">
              Return to home page
            </button>
          </Link>
        </div>
      )}
    </>
  );
}

export default PaymentForm;
