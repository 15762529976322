import React from 'react';
import Nav from '../components/Nav';
import StripeContainer from '../components/StripeContainer';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { AiFillGold } from 'react-icons/ai';
import { RiVipDiamondFill } from 'react-icons/ri';

function Checkout() {
  const { tier } = useParams();

  const tiers = {
    gold: '9.99',
    diamond: '14.99',
  };

  return (
    <>
      <Nav />
      <div className="w-full min-h-screen h-min bg-[#EAE0C8] overflow-auto  text-[#374756]">
        <div className="max-w-[1350px] w-full sm:w-[90%] min-h-screen bg-white  mx-auto justify-center md:justify-start content-start pt-[128px] relative">
          <Link to="/buyMembership">
            <FaArrowLeft className="absolute left-[30px] top-[100px] lg:top-[170px]" />
          </Link>
          {/* gold */}
          {tier === 'gold' && (
            <div className="py-1 mx-auto my-6 rounded-md w-[81%] bg-amber-200 text-amber-700">
              <div className=" m-5 pb-5 border-b-[1px] border-amber-600 ">
                <div className="flex items-center justify-center gap-3">
                  <AiFillGold className="w-8 h-8 text-amber-500" />
                  <p className="text-[20px]">Gold membership</p>
                  <AiFillGold className="w-8 h-8 text-amber-500" />
                </div>
                <p className="text-[16px] mx-auto w-max">
                  $ {tiers[tier] || ''} USD / month
                </p>
              </div>
              <ul className="text-[14px] grid md:grid-cols-3 grid-cols-1">
                <li className="w-[90%] mx-auto p-5 pt-0 ">• 25 likes / day</li>
                <li className="w-[90%] mx-auto px-5 pb-5 ">
                  • 5 favorites / day
                </li>
                <li className="w-[90%] mx-auto px-5 pb-8 ">
                  • Gold outline on profile
                </li>
              </ul>
            </div>
          )}
          {/* diamond */}
          {tier === 'diamond' && (
            <div className="py-1 mx-auto my-6 rounded-md w-[81%] bg-sky-200 text-sky-700">
              <div className=" m-5 pb-5 border-b-[1px] border-sky-600 ">
                <div className="flex items-center justify-center gap-3">
                  <RiVipDiamondFill className="w-8 h-8 text-sky-400" />
                  <p className="text-[20px]">Diamond membership</p>
                  <RiVipDiamondFill className="w-8 h-8 text-sky-400" />
                </div>
                <p className="text-[16px] mx-auto w-max">
                  $ {tiers[tier] || ''} USD / month
                </p>
              </div>
              <ul className="text-[14px] grid md:grid-cols-3 grid-cols-1">
                <li className="w-[90%] mx-auto p-5 pt-0">
                  • Unlimited likes / day
                </li>
                <li className="w-[90%] mx-auto px-5 pb-5">
                  • Unlimited favorites / day
                </li>
                <li className="w-[90%] mx-auto px-5 pb-8">
                  • Diamond outline on profile
                </li>
              </ul>
            </div>
          )}

          <StripeContainer tier={tier} />
        </div>
      </div>
    </>
  );
}

export default Checkout;
