import { Link } from 'react-router-dom';
import { Countdown } from './Countdown';
import { useGetUserData } from '../hooks/useGetUserData';

export const FavoritesPopup = (props) => {
  if (!props.popupState || props.popupState === 'hidden') return null;

  return <Content {...props} />;
};

const Content = ({ popupState, setPopupState }) => {
  const { userData } = useGetUserData();

  const favoritesToday = userData?.favoritesToday?.firstFavoritesTimestamp || 0;
  const heartsToday = userData?.heartsToday?.firstHeartTimestamp || 0;

  const data = {
    heart: {
      title: `You've reached your limit for likes today! Renewing in `,
      message: 'You can upgrade your membership for more likes',
      timestamp: heartsToday,
    },
    favorite: {
      title: `You've reached your limit for favorites today! Renewing in`,
      message: 'You can upgrade your membership for more favorites',
      timestamp: favoritesToday,
    },
  };

  const title = data?.[popupState]?.title;
  const message = data?.[popupState]?.message;
  const timestamp = data?.[popupState]?.timestamp;

  if (!userData) return null;

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full bg-black/80">
      <div className="w-3/4 max-w-[400px] p-5 bg-white rounded-lg md:w-1/2 flex justify-center flex-wrap">
        <p className="mb-4 text-center">
          {title} <Countdown timestamp={timestamp} />
        </p>
        <button
          onClick={() => setPopupState('hidden')}
          className="p-2 text-white bg-blue-500 rounded"
        >
          Close
        </button>
        <p className="w-full my-4 font-semibold text-center text-red-500">Or</p>
        <p className="mb-4 text-center">{message}</p>
        <Link
          to="/buyMembership"
          className="w-full flex items-center justify-center"
        >
          <button className="p-2 text-white bg-green-500 rounded">
            Upgrade Membership
          </button>
        </Link>
      </div>
    </div>
  );
};
