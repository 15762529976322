export const heartsLimit = {
  free: 5,
  gold: 25,
  diamond: -1,
};

export const favoritesLimit = {
  free: 1,
  gold: 5,
  diamond: -1,
};
