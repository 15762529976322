import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_FIREBASE_PROD_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PROD_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_PROD_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_PROD_MEASUREMENT_ID,
};

const firebaseConfigDev = {
  apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID,
};

const environment = process.env.REACT_APP_ENVIRONMENT;

const keys = () => {
  if (environment === 'production') return firebaseConfigProd;
  if (environment === 'development') return firebaseConfigDev;
  return firebaseConfigDev;
};

const firebaseConfig = keys();

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const storage = getStorage(app);
export default app;
export { auth };
export const db = getDatabase(app);
