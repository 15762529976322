import React from 'react';
import useOpenConversation from '../hooks/useOpenConversation';
import Loader from '../components/Loader';
import LastOnline from '../components/LastOnline';

const UserCard = ({ id, name, unreadCount, profilePicture }) => {
  const { openConversation, conversationLoading } = useOpenConversation();

  return (
    <div
      key={id}
      className="w-full h-min bg-gray-100 rounded-[2px] my-1 flex flex-wrap border-[1px] relative cursor-pointer justify-between items-center duration-150 hover:bg-gray-200 active:bg-gray-300"
      onClick={() => openConversation(id)}
    >
      <div className="flex">
        {profilePicture && (
          <img
            src={profilePicture}
            alt={`${name}'s profile`}
            className="w-[70px] h-[70px] object-cover object-center mr-4"
          />
        )}
        <div className="flex flex-col justify-center h-[70px]">
          <div className="text-[16px]">{name}</div>
          <LastOnline userId={id} className="text-[12px] gap-1">
            <LastOnline.Indicator className="w-[6px] h-[6px]" />
            <LastOnline.OnlineStatus />
          </LastOnline>
        </div>
        {unreadCount ? (
          <div className="absolute grid w-6 h-6 text-white bg-red-600 rounded-full -top-2 -left-2 place-content-center">
            {unreadCount}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {conversationLoading && <Loader size={12} className="mr-3" />}
    </div>
  );
};

export default UserCard;
