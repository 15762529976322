import React, { createContext, useContext, useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getLastOnline } from '../utils/getLastOnline';
import { cn } from '../utils/cn';

export const LastOnline = ({ children, userId, className }) => {
  const onlineStatus = useUserOnlineStatus(userId);
  const [timer, setTimer] = useState(0);

  // refresh the online status every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const wrapperClasses = cn('flex gap-2 items-center', className);

  return (
    <ComponentContext.Provider value={{ onlineStatus }}>
      <div className={wrapperClasses}>{children}</div>
      <div className="hidden">{timer}</div>
    </ComponentContext.Provider>
  );
};

const Indicator = ({ className }) => {
  const { onlineStatus } = useComponentContext();

  const indicatorClasses = cn(
    'w-2 h-2 rounded-full',
    onlineStatus.online ? 'bg-green-500' : 'bg-orange-500',
    className,
  );

  return <div className={indicatorClasses} />;
};

const OnlineStatus = ({ className }) => {
  const { onlineStatus } = useComponentContext();

  const lastOnline = getLastOnline(onlineStatus?.lastOnline);
  const isOnline = onlineStatus?.online;
  const currentStatus = isOnline ? 'Online' : lastOnline || 'Offline';

  const onlineStatusClasses = cn(
    onlineStatus.online ? 'text-green-500' : 'text-orange-500',
    className,
  );

  return <div className={onlineStatusClasses}>{currentStatus}</div>;
};

// Custom hook to fetch real-time online status
const useUserOnlineStatus = (userId) => {
  const [onlineStatus, setOnlineStatus] = useState({});

  useEffect(() => {
    if (!userId) {
      setOnlineStatus({});
      return;
    }

    const db = getDatabase();
    const onlineStatusRef = ref(db, `users/${userId}/onlineStatus`);

    const unsubscribe = onValue(onlineStatusRef, (snapshot) => {
      if (snapshot.exists()) {
        setOnlineStatus(snapshot.val());
      } else {
        setOnlineStatus({});
      }
    });

    return () => unsubscribe();
  }, [userId]);

  return onlineStatus;
};

const ComponentContext = createContext();

const useComponentContext = () => {
  const context = useContext(ComponentContext);
  if (!context)
    throw new Error(
      'useComponentContext must be used within a LastOnline component',
    );
  return context;
};

LastOnline.Indicator = Indicator;
LastOnline.OnlineStatus = OnlineStatus;

export default LastOnline;
