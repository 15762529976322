import { useCallback, useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import useUserStore from './useUserStore';

export const useGetUserData = () => {
  const [userData, setUserData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const { user } = useUserStore((state) => ({ user: state.user }));
  const userId = user.uid;

  const getUser = useCallback(async () => {
    if (!userId) return;

    setLoadingUserData(true);
    const db = getDatabase();
    const userRef = ref(db, `users/${userId}`);
    const snapshot = await get(userRef);
    const userData = snapshot.val();

    setLoadingUserData(false);
    setUserData(userData || {});
  }, [userId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return { userData, userId, loadingUserData };
};
