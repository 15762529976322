import React, { createContext, useContext } from 'react';
import { AiFillHeart, AiFillStar } from 'react-icons/ai';
import { useFavorite, useHeart } from '../hooks/useHeartOrFavorite';
import { cn } from '../utils/cn';

export const FavoriteControls = ({ children, userData, person }) => {
  return (
    <ComponentContext.Provider value={{ userData, person }}>
      {children}
    </ComponentContext.Provider>
  );
};

const Heart = ({ className, handleMax }) => {
  const { userData, person } = useComponentContext();
  const { handleHeart, heartLoading, heartError } = useHeart();

  const isLiked = userData?.likes?.includes(person.id);

  const clickHeart = () => {
    if (heartLoading) return;
    handleHeart({
      userId: userData?.id,
      personId: person.id,
      alreadyHearted: isLiked,
      handleMax,
    });
  };

  const heartClasses = cn(
    'cursor-pointer duration-150 hover:text-red-500 h-full w-7',
    heartLoading && 'animate-pulse',
    heartError && 'opacity-50 pointer-events-none',
    isLiked && 'text-red-500',
    className,
  );

  return <AiFillHeart className={heartClasses} onClick={clickHeart} />;
};

const Favorite = ({ className, handleMax }) => {
  const { userData, person } = useComponentContext();
  const { handleFavorite, favoriteLoading, favoriteError } = useFavorite();

  const isFavorited = userData?.favorites?.includes(person.id);

  const clickFavorite = () => {
    if (favoriteLoading) return;
    handleFavorite({
      userId: userData?.id,
      personId: person.id,
      alreadyFavorited: isFavorited,
      handleMax,
    });
  };

  const favoriteClasses = cn(
    'cursor-pointer duration-150 hover:fill-yellow-500 h-full w-7',
    favoriteLoading && 'animate-pulse',
    favoriteError && 'opacity-50 pointer-events-none',
    isFavorited && 'fill-yellow-500',
    className,
  );

  return <AiFillStar className={favoriteClasses} onClick={clickFavorite} />;
};

const ComponentContext = createContext();

const useComponentContext = () => {
  const context = useContext(ComponentContext);
  if (!context) {
    throw new Error(
      'useComponentContext must be used within a FavoriteControls component',
    );
  }
  return context;
};

FavoriteControls.Heart = Heart;
FavoriteControls.Favorite = Favorite;

export default FavoriteControls;
