import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';

export const useGetConversation = (params) => {
  const [conversation, setConversation] = useState(null);
  const [permitted, setPermitted] = useState(true);
  const { conversationId } = params;

  useEffect(() => {
    if (!conversationId) return;

    const db = getDatabase();
    const conversationRef = ref(db, `messages/${conversationId}`);

    const unsubscribe = onValue(
      conversationRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const convData = snapshot.val();
          setConversation(convData);
        } else {
          console.log('No conversation found with id:', conversationId);
          setConversation(null);
        }
      },
      (error) => {
        const permissionDenied = error.message.includes('permission_denied');
        setPermitted(!permissionDenied);
        console.error('Error fetching conversation:', error);
      },
    );

    // Cleanup function
    return () => off(conversationRef, 'value', unsubscribe);
  }, [conversationId]);

  const messagesArray = () => {
    const hasMessages = conversation?.messages;
    if (!hasMessages) return [];

    const messageObject = ([id, message]) => ({ id, ...message });
    return Object.entries(conversation?.messages).map(messageObject);
  };

  const messages = messagesArray();

  return {
    messages,
    permitted,
    loadingConversation: !conversation,
  };
};
