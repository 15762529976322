import { useCallback, useEffect, useState } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import { Checkbox } from '../components/Checkbox';
import Nav from '../components/Nav';
import Loader from '../components/Loader';
import useUserStore from '../hooks/useUserStore';
import { cn } from '../utils/cn';

const Settings = () => {
  const [sendEmailsChecked, setSendEmailsChecked] = useState(false);
  const [notificationEmailsChecked, setNotificationEmailsChecked] =
    useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const { user } = useUserStore((state) => ({ user: state.user }));
  const userId = user.uid;

  const getUser = useCallback(async () => {
    if (!userId) return;

    setLoadingUserData(true);
    const db = getDatabase();
    const userRef = ref(db, `users/${userId}`);
    const snapshot = await get(userRef);
    const userData = snapshot.val();

    const sendEmails = userData?.settings?.sendEmails;
    const sendUndetermined = sendEmails === undefined;
    const sendEmailsChecked = sendUndetermined ? true : sendEmails;

    const notificationEmails = userData?.settings?.notificationEmails;
    const notificationUndetermined = notificationEmails === undefined;
    const notificationEmailsChecked = notificationUndetermined
      ? true
      : notificationEmails;

    setLoadingUserData(false);
    setSendEmailsChecked(sendEmailsChecked);
    setNotificationEmailsChecked(notificationEmailsChecked);
  }, [userId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const saveSettings = async () => {
    setSavingSettings(true);
    const db = getDatabase();
    const userRef = ref(db, `users/${userId}/settings`);
    try {
      await set(userRef, {
        sendEmails: sendEmailsChecked,
        notificationEmails: notificationEmailsChecked,
      });
      alert('Settings saved!');
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setSavingSettings(false);
    }
  };

  const buttonClasses = cn(
    'w-full h-[44px] rounded-[6px] hover:bg-[#c51728] bg-[#9B111E] text-white duration-150',
    savingSettings && 'animate-pulse pointer-events-none',
  );

  if (loadingUserData)
    return (
      <div className="flex items-center justify-center w-full pt-12">
        <Loader size={30} />
      </div>
    );

  return (
    <>
      <Nav />
      <div className="w-full min-h-screen h-min bg-[#EAE0C8] overflow-hidden  text-[#374756]">
        <div className="max-w-[1350px] w-full sm:w-[90%] h-screen overflow-auto p-10 pt-[120px] bg-white flex flex-wrap content-start mx-auto hide-scrollbar">
          <div className="flex flex-col gap-10 mt-12">
            <div className="text-3xl font-bold">Email settings:</div>
            <div className="flex flex-col gap-5">
              <Checkbox
                checked={sendEmailsChecked}
                onChange={setSendEmailsChecked}
                label="Send me emails"
              />
              <Checkbox
                checked={notificationEmailsChecked}
                onChange={setNotificationEmailsChecked}
                disabled={!sendEmailsChecked}
                label="Send me notification emails"
                className="ml-5"
              />
              <button onClick={saveSettings} className={buttonClasses}>
                Save settings
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
