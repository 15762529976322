import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import useUserStore from './useUserStore';

export const useGetUnreadMessages = () => {
  const [unreadMessages, setUnreadMessages] = useState({
    conversations: {},
    totalMessages: 0,
  });
  const { user } = useUserStore((state) => ({
    user: state.user,
  }));

  const userId = user?.uid;

  useEffect(() => {
    if (!userId) return;

    const db = getDatabase();
    const unreadMessagesRef = ref(db, `users/${userId}/unreadMessages`);

    // Subscribe to real-time updates
    const unsubscribe = onValue(unreadMessagesRef, (snapshot) => {
      if (snapshot.exists()) {
        const unreadMessages = snapshot.val();

        const totalMessages = Object.values(unreadMessages || {}).reduce(
          (acc, curr) => acc + curr,
          0,
        );

        setUnreadMessages({
          conversations: unreadMessages || {},
          totalMessages,
        });
      } else {
        setUnreadMessages({});
      }
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, [userId]);

  return unreadMessages;
};

export default useGetUnreadMessages;
