import React, { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { getAuth, deleteUser } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update, remove } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  deleteObject,
  getDownloadURL,
} from 'firebase/storage';

function EditProfile() {
  const auth = getAuth();
  const database = getDatabase();

  const holdOptions = {
    gender: ['Male', 'Female'],
    children: ['No', 'Yes'],
    ethnicity: [
      'Black / African Descent',
      'Asian',
      'Hispanic / Latin',
      'Middle Eastern',
      'Mixed',
      'White / Caucasian',
    ],
    relationship: [
      'Friendship',
      'Short term dating',
      'Long term dating',
      'Casual dating',
    ],
    religion: [
      'Buddhist',
      'Christian',
      'Christian - Catholic',
      'Hindu',
      'Jewish',
      'Muslim',
      'Not religious',
      'Sikh',
      'Other',
    ],
  };

  const [userUID, setUserUID] = useState(null);
  const [userName, setUserName] = useState('');
  const [person, setPerson] = useState({});

  const [firstName, setFirstName] = useState('');
  const [selectedAge, setSelectedAge] = useState(18);
  const [location, setLocation] = useState('');
  const [gender, setGender] = useState('');
  const [children, setChildren] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [seekingRelationship, setSeekingRelationship] = useState('');
  const [religion, setReligion] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [seeking, setSeeking] = useState('');

  const navigate = useNavigate();

  const allFieldsFilled = () => {
    return (
      firstName &&
      selectedAge &&
      location &&
      gender &&
      children &&
      ethnicity &&
      seekingRelationship &&
      religion &&
      aboutMe &&
      seeking
    );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (allFieldsFilled()) {
      const userProfile = {
        name: firstName,
        age: selectedAge,
        location,
        gender,
        children,
        ethnicity,
        seekingRelationship,
        religion,
        aboutMe,
        seeking,
      };

      const userRef = ref(database, `users/${userUID}`);
      update(userRef, userProfile)
        .then(() => {
          navigate(`/dashboard`);
        })
        .catch((error) => {
          console.error('Error updating user profile:', error);
        });
    } else {
      console.log('Not all fields are filled');
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        'Are you sure you want to delete your account? This action is irreversible.',
      )
    ) {
      try {
        // Delete data from Realtime Database
        const userRef = ref(database, `users/${userUID}`);
        await remove(userRef);

        // Check if data exists in Firebase Storage before deletion
        const storage = getStorage();
        const userStorageRef = storageRef(storage, `images/${userUID}`);

        const objectExists = await getDownloadURL(userStorageRef)
          .then(() => true)
          .catch(() => false);

        if (objectExists) {
          await deleteObject(userStorageRef);
        } else {
          console.log('User data not found in storage, skipping delete.');
        }

        // Delete user's authentication account
        await deleteUser(auth.currentUser);

        navigate('/');
      } catch (error) {
        console.error(
          'Error deleting user account and associated data:',
          error,
        );
      }
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserUID(user.uid);
    }
  }, [auth]);

  useEffect(() => {
    if (userUID) {
      const userRef = ref(database, `users/${userUID}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setUserName(data.name);
            setPerson(data);
          } else {
            console.log('No data available for this user.');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [userUID, database]);

  return (
    <>
      <Nav />
      <div className="w-full min-h-screen h-min bg-[#EAE0C8] overflow-hidden  text-[#374756]">
        <div className="max-w-[1350px] w-full sm:w-[90%] h-screen overflow-auto p-10 bg-[#F8F8F8] flex flex-wrap content-start mx-auto">
          <div className="w-full h-min mt-[140px] ">
            <h1 className="text-[30px]">Edit Profile</h1>
            <p>
              Welcome {userName}! Answering these profile questions will update
              your profile information.
            </p>
            <div className="text-gray-600 bg-gray-200 hover:bg-gray-300 duration-150 rounded-[2px] p-2 mt-2 w-max">
              <Link to={`/user/${person.id}`}>View My Profile</Link>
            </div>
          </div>
          {/* form for updating user data */}
          <form onSubmit={handleFormSubmit} className="relative">
            {/* first name */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                First Name: <span className="text-green-600">{firstName}</span>
              </p>
              <input
                required
                placeholder="name"
                className="pl-2 border-[1px]"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            {/* age */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                Age: <span className="text-green-600">{selectedAge}</span>
              </p>
              <input
                required
                type="range"
                min={18}
                max={100}
                value={selectedAge}
                onChange={(e) => {
                  const newAge = e.target.value;
                  setSelectedAge(newAge);
                }}
              />
            </div>
            {/* gender */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                Gender: <span className="text-green-600">{gender}</span>
              </p>
              <div>
                {holdOptions.gender.map((item) => (
                  <div
                    className="bg-gray-200 hover:bg-gray-300 cursor-pointer duration-150 w-min p-1 rounded-[2px] my-1"
                    onClick={() => setGender(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            {/* location */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                Location: <span className="text-green-600">{location}</span>
              </p>
              <input
                required
                placeholder="input here"
                className="pl-2 border-[1px]"
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            {/* children */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                Do you have children?{' '}
                <span className="text-green-600">{children}</span>
              </p>
              <div>
                {holdOptions.children.map((item) => (
                  <div
                    className="bg-gray-200 hover:bg-gray-300 cursor-pointer duration-150 w-min p-1 rounded-[2px] my-1"
                    onClick={() => setChildren(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            {/* ethnicity */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                Ethnicity: <span className="text-green-600">{ethnicity}</span>
              </p>
              <div>
                {holdOptions.ethnicity.map((item) => (
                  <div
                    className="bg-gray-200 hover:bg-gray-300 cursor-pointer duration-150 p-1 rounded-[2px] my-1 w-max"
                    onClick={() => setEthnicity(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            {/* relationship they are seeking */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                What type of relationship are you looking for?{' '}
                <span className="text-green-600">{seekingRelationship}</span>
              </p>
              <div>
                {holdOptions.relationship.map((item) => (
                  <div
                    className="bg-gray-200 hover:bg-gray-300 cursor-pointer duration-150 p-1 rounded-[2px] my-1 w-max"
                    onClick={() => setSeekingRelationship(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            {/* religion */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                Religion:
                <span className="text-green-600">{religion}</span>
              </p>
              <div>
                {holdOptions.religion.map((item) => (
                  <div
                    className="bg-gray-200 hover:bg-gray-300 cursor-pointer duration-150 p-1 rounded-[2px] my-1 w-max"
                    onClick={() => setReligion(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            {/* about */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                About myself: <span className="text-green-600">{aboutMe}</span>
              </p>
              <input
                required
                placeholder="Input text here"
                className="pl-2 border-[1px]"
                onChange={(e) => setAboutMe(e.target.value)}
              />
            </div>
            {/* seeking */}
            <div className="w-full mt-5 h-min border-t-[1px] pt-2 ">
              <p>
                What I'm looking for in a partner:{' '}
                <span className="text-green-600">{seeking}</span>
              </p>
              <input
                required
                placeholder="Input text here"
                className="pl-2 border-[1px]"
                onChange={(e) => setSeeking(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className={`${
                allFieldsFilled()
                  ? 'bg-blue-200 hover:bg-blue-300'
                  : 'bg-gray-300'
              } my-6 cursor-pointer duration-150 p-1 px-2 rounded-[2px] w-max`}
              disabled={!allFieldsFilled()}
            >
              Submit
            </button>
          </form>
          <div className="w-full">
            <button
              className="bg-red-200 hover:bg-red-400  cursor-pointer duration-150 p-1 px-2 rounded-[2px] w-max h-min"
              onClick={handleDeleteAccount}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProfile;
