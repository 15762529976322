import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import { stripeKey } from '../utils/stripeKey';

function StripeContainer({ tier }) {
  const stripe = loadStripe(stripeKey);

  return (
    <Elements stripe={stripe}>
      <PaymentForm tier={tier} />
    </Elements>
  );
}

export default StripeContainer;
