import React, { useState, useEffect, useRef } from 'react';
import vidLandscape from '../assets/videos/landscape.mp4';
import landingMobile from '../assets/images/mobile-landing.jpg';
import { cn } from '../utils/cn';

import logoHeart from '../assets/images/logotwo.png';
import { Link, useNavigate } from 'react-router-dom';
import useUserStore from '../hooks/useUserStore';
import PinaSoulmateSVG2 from '../assets/svgs/PinaySoulmateSVG2';
import axios from 'axios';
import { backendUrl } from '../utils/backendUrl';

function Landing() {
  const [widthUnder, setWidthUnder] = useState(window.innerWidth <= 700);
  const [waited, setWaited] = useState(false);
  const [vidDisplayed, setVidDisplayed] = useState(false);
  const [userCount, setUserCount] = useState(null);

  const navigate = useNavigate();
  const { authState } = useUserStore((state) => ({
    authState: state.authState,
  }));

  const videoRef = useRef(null);

  const restartVideo = () => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    videoEl.currentTime = 0;
    videoEl.play();
  };

  // only autoplay video if muted to avoid app crash (for chrome fix)
  useEffect(() => {
    if (authState === 'logged-in') return navigate('/dashboard');

    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.play();
    }
  }, [authState, navigate]);

  const getUserCount = async () => {
    try {
      const { data } = await axios.get(`${backendUrl}/get-user-count`);
      setUserCount(data?.total.toLocaleString());
    } catch (err) {
      console.log('could not fetch user count', err);
    }
  };

  useEffect(() => {
    // Window resize logic
    const handleResize = () => {
      setWidthUnder(window.innerWidth <= 700);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    // Timer logic
    const timer = setTimeout(() => {
      setWaited(true);
      restartVideo();
    }, 3100);
    setTimeout(() => {
      setVidDisplayed(true);
    }, 5000);

    // Video event listener logic
    const videoEl = videoRef.current;
    const handleCanPlayThrough = () => {
      // setIsVideoLoaded(true);
    };
    if (videoEl) {
      videoEl.addEventListener('canplaythrough', handleCanPlayThrough);
    }

    getUserCount();

    // Cleanup functions
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
      if (videoEl) {
        videoEl.removeEventListener('canplaythrough', handleCanPlayThrough);
      }
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full h-screen overflow-hidden">
      <div
        className={cn(
          'w-full absolute top-0 left-0 bg-[#1a1a1a] overflow-hidden duration-[2000ms] ease-in-out',
          waited ? 'h-0' : 'h-full',
        )}
      >
        <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-[50vh] left-[50vw] scale-[25%] sm:scale-[40%]">
          <PinaSoulmateSVG2 />
        </div>
      </div>

      <div
        className={cn(
          'absolute overflow-hidden  duration-[2000ms] ease-in-out',
          waited
            ? 'w-full h-screen top-0 left-0'
            : 'w-[180vw] h-[180vh] left-[-40vw] top-[40vh]',
        )}
      >
        {!widthUnder ? (
          <div className="w-full h-full ">
            <video
              ref={videoRef}
              className="fullHeightVideo"
              src={vidLandscape}
              autoPlay
              muted
              loop
            />
          </div>
        ) : (
          <div className="w-full h-full">
            <img
              src={landingMobile}
              className="absolute top-1/2 left-1/2 min-w-full min-h-full h-[107vh] -translate-x-1/2 -translate-y-1/2 object-cover -z-10 overflow-hidden brightness-75"
              alt="mobile landing"
            />
          </div>
        )}

        {/* header nav */}
        <div
          className={cn(
            'bg-[#363636a4] h-[90px] flex w-full absolute top-0 text-white justify-end items-center duration-1000 ease-in-out',
            vidDisplayed ? 'opacity-100' : 'opacity-0',
          )}
        >
          <div className="absolute flex items-center justify-start w-full h-full lg:justify-center">
            <img src={logoHeart} alt="heart" className="w-16 h-16" />
            <div className="text-[30px] font-extrabold hidden lg:inline">
              PinaSoulmate
            </div>
          </div>
          <div className="flex gap-5 pr-5">
            <Link to="/login">
              <button className="text-[15px] text-[#9B111E] bg-white py-3 px-6 relative top-0 font-semibold cursor-pointer duration-300 hover:-top-1">
                Log In
              </button>
            </Link>
            <Link to="/setup">
              <button className="relative whitespace-nowrap text-[15px] text-white bg-[#9b111e] py-3 px-6 font-semibold cursor-pointer duration-300 top-0 hover:-top-1">
                Sign Up
              </button>
            </Link>
          </div>
        </div>

        {/* body */}
        <h2 className="text-[40px] absolute w-full font-serif font-bold text-white  h-min top-[40%] md:top-[70%] ">
          <div className="w-[80%] h-full  mx-auto">
            <div
              className={cn(
                'mb-10 md:mb-4  duration-[2000ms] ease-in-out text-[24px] sm:text-[40px]',
                vidDisplayed ? 'opacity-100' : 'opacity-0',
              )}
            >
              Connecting Hearts Across Borders
            </div>

            <div className="flex flex-wrap">
              <p
                className={cn(
                  'duration-[2000ms] ease-in-out delay-300 text-[24px] sm:text-[40px]',
                  vidDisplayed ? 'opacity-100' : 'opacity-0',
                )}
              >
                Find Love today{' '}
              </p>
              <Link
                to="/setup"
                className="relative top-0 duration-300 hover:-top-1"
              >
                <button
                  className={cn(
                    'hidden ml-10 md:block text-[18px] text-white bg-[#9b111e] p-3 px-6  font-semibold cursor-pointer relative ease-in-out delay-[600ms] duration-[2000ms]',
                    vidDisplayed ? 'opacity-100' : 'opacity-0',
                  )}
                >
                  Join now
                </button>
              </Link>
            </div>
            <Link className="m-3" to="/setup">
              <button
                className={cn(
                  'block md:hidden text-[18px] text-white bg-[#9b111e] p-3 px-6  font-semibold cursor-pointer ease-in-out delay-[600ms] duration-[2000ms]',
                  vidDisplayed ? 'opacity-100' : 'opacity-0',
                )}
              >
                Join now
              </button>
            </Link>
          </div>
        </h2>
        {userCount != null && (
          <span
            className={cn(
              'absolute text-[#ffffffb2] bottom-2 right-4 delay-[2000ms] duration-[2000ms]',
              vidDisplayed ? 'opacity-100' : 'opacity-0',
            )}
          >
            {userCount} members so far!
          </span>
        )}
      </div>
    </div>
  );
}

export default Landing;
