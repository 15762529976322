import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useUserStore from './useUserStore';

const useFirebaseAuth = () => {
  const setUser = useUserStore((state) => state.setUser);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user); // This will also set the state
    });

    return () => unsubscribe();
  }, [setUser]);

  // No return value needed unless you want to expose something
};

export default useFirebaseAuth;
