import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { ref, onValue, remove } from 'firebase/database';
import { deleteObject, ref as storageRef, listAll } from 'firebase/storage';
import { Link } from 'react-router-dom';

function Admin() {
  const [reports, setReports] = useState([]);

  const timeSince = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + ' years ago';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ' months ago';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + ' days ago';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + ' hours ago';
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  };

  const deleteUserFromDatabase = (userId) => {
    const userRef = ref(db, `users/${userId}`); // Fix here: Added the users path.
    remove(userRef);
  };

  const deleteUserStorageImages = async (userId) => {
    try {
      const userImagesDirectoryRef = storageRef(storage, `images/${userId}`);

      // List all files in the directory
      const fileListResult = await listAll(userImagesDirectoryRef); // Use listAll method

      const deletionPromises = fileListResult.items.map((fileRef) => {
        return deleteObject(fileRef);
      });

      // Wait for all files to be deleted
      await Promise.all(deletionPromises);
    } catch (error) {
      console.error('Error deleting storage images:', error);
    }
  };

  const handleDeleteUser = (userId) => {
    deleteUserFromDatabase(userId);
    deleteUserStorageImages(userId);
  };

  const fetchUserImageUrls = async (userId) => {
    try {
      const userImagesDirectoryRef = storageRef(storage, `images/${userId}`);
      const fileListResult = await listAll(userImagesDirectoryRef);
      const downloadUrlsPromises = fileListResult.items.map((fileRef) =>
        fileRef.getDownloadURL()
      );
      const downloadUrls = await Promise.all(downloadUrlsPromises);
      return downloadUrls;
    } catch (error) {
      console.error('Error fetching user image URLs:', error);
      return [];
    }
  };

  const handleRemoveReport = (userId, reportId) => {
    const reportRef = ref(db, `users/${userId}/reports/${reportId}`);
    remove(reportRef);
  };

  useEffect(() => {
    const dbRef = ref(db, 'users');

    onValue(dbRef, async (snapshot) => {
      const data = snapshot.val();
      const allReports = [];

      for (let userId in data) {
        const user = data[userId];
        if (user.reports) {
          for (let reportId in user.reports) {
            const report = user.reports[reportId];

            const userImageUrls = await fetchUserImageUrls(userId); // fetch image URLs
            allReports.push({
              userId: userId,
              reportId: reportId,
              userName: user.name || userId,
              reportText: report.reason,
              timeAgo: timeSince(new Date(report.timestamp)),
              userBilling: user.billing || 'free',
              userImageUrls: userImageUrls
            });
          }
        }
      }

      setReports(allReports);
    });
  }, []);

  return (
    <div className="w-full min-h-screen bg-gray-200">
      <h1 className="text-[24px] ml-5 pt-5 font-bold mb-5">Admin Reports</h1>
      <Link
        to="/dashboard"
        className="px-3 ml-5 duration-150 bg-orange-400 hover:bg-orange-500 rounded-xl">
        go to dashboard
      </Link>
      <div className="flex flex-wrap w-full">
        {reports.map((report, index) => (
          <div className="border-[1px] p-2 bg-white rounded-[5px] equal-shadow shadow-equal w-max m-5 max-w-[500px] overflow-auto">
            <div key={index}>
              <strong>User:</strong> {report.userName} <br />
              <strong>Membership Type:</strong> {report.userBilling} <br />
              <strong>ID:</strong> {report.userId} <br />
              <p className="w-full overflow-auto h-min"></p>
              <strong>Report:</strong> {report.reportText} <br />
              <strong>Submitted:</strong> {report.timeAgo} <br />
              {/* Show user images */}
              {JSON.stringify(report.userImages)}
              {report.userImageUrls.map((imageUrl, imageIndex) => (
                <img
                  key={imageIndex}
                  src={imageUrl} // Directly use the URL now
                  alt={`User  ${imageIndex}`}
                />
              ))}
              <button
                onClick={() => handleDeleteUser(report.userId)}
                className="px-3 bg-red-400 hover:bg-red-500 rounded-xl">
                Delete User
              </button>
              <button
                onClick={() =>
                  handleRemoveReport(report.userId, report.reportId)
                }
                className="px-3 ml-5 bg-yellow-300 hover:bg-yellow-400 rounded-xl">
                Remove Report
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Admin;
