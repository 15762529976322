import React, { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref as dbRef, onValue } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  listAll,
  getDownloadURL,
} from 'firebase/storage';
import Loader from '../components/Loader';
import UserCard from './UserCard';

function Notifications() {
  const [userUID, setUserUID] = useState('');
  const [senders, setSenders] = useState({});
  const [senderDetails, setSenderDetails] = useState(null);

  const auth = getAuth();
  const database = getDatabase();
  const storage = getStorage();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      setUserUID(user.uid);
      const messagesRef = dbRef(database, `users/${user.uid}/unreadMessages`);
      onValue(messagesRef, (snapshot) => {
        const messagesData = snapshot.val();
        setSenders(messagesData || {});
      });
    }
  }, [userUID, database, user]);

  useEffect(() => {
    async function fetchUserDetails() {
      let details = [];
      for (const [uid, unreadCount] of Object.entries(senders)) {
        // Fetch the name
        const nameSnapshot = await new Promise((resolve) =>
          onValue(dbRef(database, `users/${uid}/name`), resolve),
        );
        const name = nameSnapshot.val();

        // Fetch the image URL
        const imageListSnapshot = await listAll(
          storageRef(storage, `images/${uid}`),
        );
        let imageURL = '';
        if (imageListSnapshot.items.length > 0) {
          imageURL = await getDownloadURL(imageListSnapshot.items[0]);
        }

        details.push({ uid, name, unreadCount, imageURL });
      }

      // Sort and set sender details
      details.sort((a, b) => b.unreadCount - a.unreadCount);
      setSenderDetails(details);
    }

    fetchUserDetails();
  }, [senders, database, storage]);

  const noSenders = senderDetails?.length <= 0;
  const loadingSenders = senderDetails === null;

  return (
    <>
      <Nav />
      <div className="w-full min-h-[100dvh] h-min bg-[#EAE0C8] text-[#374756] overflow-auto">
        <div className="max-w-[1350px] w-full sm:w-[90%] h-screen p-10 bg-white flex flex-wrap mx-auto justify-center md:justify-start content-start">
          <div className="h-[20px] md:h-[108px] w-full"></div>

          <div className="flex items-center gap-4 my-10">
            <div className="h-[48px] w-[192px] text-start bg-[#9b111e] text-white grid place-content-center">
              Your conversations
            </div>
          </div>

          <>
            {/* Display sender details here */}
            <div className="flex flex-wrap w-full ">
              {noSenders ? (
                <div>No senders</div>
              ) : loadingSenders ? (
                <div className="flex items-center justify-center w-full text-center">
                  <Loader size={15} />
                </div>
              ) : (
                senderDetails?.map(({ imageURL, name, uid, unreadCount }) => (
                  <UserCard
                    key={uid}
                    id={uid}
                    name={name}
                    profilePicture={imageURL}
                    unreadCount={unreadCount}
                  />
                ))
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default Notifications;
