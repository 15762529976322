import { useLocation, useNavigate } from 'react-router-dom';
import useUserStore from '../hooks/useUserStore';
import { useEffect } from 'react';

const AuthorizedPage = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authState } = useUserStore((state) => ({
    authState: state.authState,
  }));

  const loggedIn = authState === 'logged-in';

  useEffect(() => {
    if (!loggedIn && location.pathname !== '/login') {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
    }
  }, [loggedIn, location, navigate]);

  if (loggedIn) return children;
  if (!loggedIn) return <div>Unauthorized</div>;
};

export default AuthorizedPage;
