const environment = process.env.REACT_APP_ENVIRONMENT;
const prodKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD;
const testKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;

const determineStripeKey = () => {
  if (environment === 'production') return prodKey;
  if (environment === 'development') return testKey;
};

export const stripeKey = determineStripeKey();
