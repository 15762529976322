import React, { useEffect, useState } from 'react';
import { cn } from '../utils/cn';
import { useLocation, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, list, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as dbRef, onValue } from 'firebase/database';
import { useSignOut } from '../hooks/useSignOut';
import MessagesTab from './MessagesTab';
import useGetUnreadMessages from '../hooks/useGetUnreadMessages';

function Nav() {
  const [navClicked, setNavClicked] = useState(false);
  const [browse, setBrowse] = useState(false);
  const [lists, setLists] = useState(false);
  const [messages, setMessages] = useState(false);
  const [profile, setProfile] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1000);

  const [browseDesktop, setBrowseDesktop] = useState(false);
  const [listsDesktop, setListsDesktop] = useState(false);
  const [messagesDesktop, setMessagesDesktop] = useState(false);

  const location = useLocation();
  const [holdPathname] = useState(location.pathname);

  const [profileClicked, setProfileClicked] = useState(false);

  const [editProfile, setEditProfile] = useState(false);
  const [buyMembership, setBuyMembership] = useState(false);
  const [settings, setSettings] = useState(false);
  const [logOut, setLogOut] = useState(false);

  const [userUID, setUserUID] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  const [billing, setBilling] = useState(null);

  if (billing === 'hello') {
    console.log('hi');
  }

  const auth = getAuth();

  const unreadMessages = useGetUnreadMessages();
  const { totalMessages } = unreadMessages;

  const handleResize = () => {
    setIsWideScreen(window.innerWidth > 1024);
  };

  const handleSignOut = useSignOut();

  useEffect(() => {
    if (userUID) {
      const db = getDatabase();
      const userRef = dbRef(db, `users/${userUID}/billing`);

      const unsubscribe = onValue(
        userRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setBilling(snapshot.val());
          } else {
            console.log('No user data found!');
          }
        },
        (error) => {
          console.error('Error fetching user data:', error);
        },
      );

      // Cleanup function to unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [userUID]);

  useEffect(() => {
    if (userUID) {
      const fetchProfileImage = async () => {
        const storage = getStorage();
        const userImagesRef = ref(storage, `images/${userUID}`);

        try {
          const images = await list(userImagesRef);
          if (images.items.length > 0) {
            const imageUrl = await getDownloadURL(images.items[0]);
            setProfileImageUrl(imageUrl);
          }
        } catch (error) {
          console.error('Error fetching profile image:', error);
        }
      };

      fetchProfileImage();
    }
  }, [userUID]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserUID(user.uid);
    }
  }, [auth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize); // Add event listener on mount

    // Cleanup: remove event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let browseTimer, listsTimer, messagesTimer, profileTimer;

    if (navClicked) {
      browseTimer = setTimeout(() => {
        setBrowse(true);
      }, 200);

      listsTimer = setTimeout(() => {
        setLists(true);
      }, 400);

      messagesTimer = setTimeout(() => {
        setMessages(true);
      }, 600);

      profileTimer = setTimeout(() => {
        setProfile(true);
      }, 800);
    }

    if (!navClicked) {
      setBrowse(false);
      setLists(false);
      setMessages(false);
      setProfile(false);
    }

    // Cleanup function
    return () => {
      clearTimeout(browseTimer);
      clearTimeout(listsTimer);
      clearTimeout(messagesTimer);
      clearTimeout(profileTimer);
    };
  }, [navClicked]);

  useEffect(() => {
    let editProfileTimer, buyMembershipTimer, settingsTimer, logoutTimer;

    if (profileClicked) {
      editProfileTimer = setTimeout(() => {
        setEditProfile(true);
      }, 200);

      buyMembershipTimer = setTimeout(() => {
        setBuyMembership(true);
      }, 400);

      settingsTimer = setTimeout(() => {
        setSettings(true);
      }, 600);

      logoutTimer = setTimeout(() => {
        setLogOut(true);
      }, 800);
    }

    if (!profileClicked) {
      setEditProfile(false);
      setBuyMembership(false);
      setSettings(false);
      setLogOut(false);
    }

    // Cleanup function
    return () => {
      setEditProfile(editProfileTimer);
      setBuyMembership(buyMembershipTimer);
      setSettings(settingsTimer);
      setLogOut(logoutTimer);
    };
  }, [profileClicked]);

  return (
    <>
      {isWideScreen ? (
        <div className="w-full h-[128px] bg-[#9b111e] flex flex-wrap fixed top-0 pl-10 z-50">
          <div className="grid h-full ml-5 place-content-center">
            <Link
              to="/dashboard"
              className="text-[30px] font-extrabold text-white "
            >
              PinaSoulmate
            </Link>
          </div>

          <div className="grid h-full grid-cols-4 ml-10 text-white">
            <Link
              to="/dashboard"
              className={cn(
                'flex items-center justify-center px-5 duration-300 cursor-pointer relative',
              )}
              onMouseEnter={() => setBrowseDesktop(true)}
              onMouseLeave={() => setBrowseDesktop(false)}
            >
              Browse
              <div
                className={cn(
                  'absolute -bottom-[4px] h-[4px] w-0 bg-[#F0C7CD] duration-300 ease-in-out left-0',
                  browseDesktop && 'w-full',
                )}
              ></div>
              {holdPathname === '/dashboard' && (
                <div className="absolute -bottom-[4px] h-[4px] w-full bg-[#b6636b]"></div>
              )}
            </Link>

            <Link
              to="/lists"
              className={cn(
                'flex items-center justify-center px-5 duration-300 cursor-pointer relative',
              )}
              onMouseEnter={() => setListsDesktop(true)}
              onMouseLeave={() => setListsDesktop(false)}
            >
              Lists
              <div
                className={cn(
                  'absolute -bottom-[4px] h-[4px] w-0 bg-[#F0C7CD] duration-300 ease-in-out left-0',
                  listsDesktop && 'w-full',
                )}
              ></div>
              {holdPathname === '/lists' && (
                <div className="absolute -bottom-[4px] h-[4px] w-full bg-[#b6636b]"></div>
              )}
            </Link>

            <Link
              to="/notification"
              className={cn(
                'flex items-center justify-center px-5 duration-300 cursor-pointer relative',
              )}
              onMouseEnter={() => setMessagesDesktop(true)}
              onMouseLeave={() => setMessagesDesktop(false)}
            >
              <MessagesTab />
              <div
                className={cn(
                  'absolute -bottom-[4px] h-[4px] w-0 bg-[#F0C7CD] duration-300 ease-in-out left-0',
                  messagesDesktop && 'w-full',
                )}
              ></div>
              {holdPathname === '/notification' && (
                <div className="absolute -bottom-[4px] h-[4px] w-full bg-[#b6636b]"></div>
              )}
            </Link>
          </div>
          {/* show what membership they have */}
          {/* <div className="text-white text-[20px] mt-12">
            {billing === 'free' && <p>FREE member</p>}
          </div>
          <div className="text-white text-[20px] mt-12">
            {billing === 'gold' && (
              <p>
                <span className="font-semibold text-yellow-300">GOLD</span>{' '}
                member
              </p>
            )}
            {billing === 'diamond' && (
              <p>
                <span className="font-semibold text-sky-300">DIAMOND</span>{' '}
                member
              </p>
            )}
          </div> */}
          {/* users profile image */}
          <img
            src={profileImageUrl}
            alt="Profile"
            className="w-[81px] h-[81px] rounded-full absolute top-[27px] right-[5%] cursor-pointer object-cover object-center"
            onClick={() => setProfileClicked(!profileClicked)}
          />

          <div
            className={cn(
              'w-[200px] h-min absolute right-0 top-[128px]',
              !profileClicked && 'pointer-events-none',
            )}
          >
            <Link to="/editProfile">
              <p
                className={cn(
                  'p-[14px] pl-5 w-full  bg-gray-200 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                  editProfile && 'opacity-100 bottom-0',
                )}
              >
                Edit profile
              </p>
            </Link>
            <Link to="/buyMembership">
              <p
                className={cn(
                  'p-[14px] pl-5 w-full  bg-gray-200 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                  buyMembership && 'opacity-100 bottom-0',
                )}
              >
                Buy membership
              </p>
            </Link>
            <Link to="/settings">
              <p
                className={cn(
                  'p-[14px] pl-5 w-full  bg-gray-200 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                  settings && 'opacity-100 bottom-0',
                )}
              >
                Settings
              </p>
            </Link>
            <Link to="/">
              <p
                className={cn(
                  'p-[14px] pl-5 w-full  bg-gray-200 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                  logOut && 'opacity-100 bottom-0',
                )}
                onClick={handleSignOut}
              >
                Log out
              </p>
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full h-[60px] bg-[#9b111e] flex flex-col justify-center fixed top-0 z-50">
            <Link
              to="/dashboard"
              className="text-[20px] font-extrabold text-white  ml-10 w-min"
            >
              PinaSoulmate
            </Link>
            <div
              className="w-[30px] h-[30px] flex flex-col justify-center space-y-2 absolute right-10 cursor-pointer duration-300 scale-75"
              onClick={() => setNavClicked(!navClicked)}
            >
              <div
                className={cn(
                  'absolute -top-2 -left-4 w-6 h-6 bg-white rounded-full text-red-500 text-xs flex items-center justify-center font-bold z-10',
                  !totalMessages ? 'hidden' : 'visible',
                )}
              >
                {totalMessages}
              </div>
              <div
                className={cn(
                  'w-full h-[2px] bg-white duration-300 transform',
                  navClicked && 'rotate-45 translate-y-2.5',
                )}
              ></div>

              <div
                className={cn(
                  'w-full h-[2px] bg-white duration-100',
                  navClicked && 'opacity-0',
                )}
              ></div>

              <div
                className={cn(
                  'w-full h-[2px] bg-white duration-300 transform',
                  navClicked && '-rotate-45 -translate-y-2.5',
                )}
              ></div>
            </div>
          </div>
          <div
            className={cn(
              'fixed top-[60px] w-full z-50',
              !navClicked && 'pointer-events-none',
            )}
          >
            <Link to="/dashboard">
              <p
                className={cn(
                  'p-[14px] pl-5 w-full h-[60px] bg-gray-200 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                  browse && 'opacity-100 bottom-0 text-[20px] ',
                )}
              >
                Browse
              </p>
            </Link>
            <Link to="/lists">
              <p
                className={cn(
                  'p-[14px] pl-5 w-full h-[60px] bg-gray-200 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                  lists && 'opacity-100 bottom-0 text-[20px] ',
                )}
              >
                Lists
              </p>
            </Link>
            <Link to="/notification">
              <p
                className={cn(
                  'p-[14px] pl-5 w-full h-[60px] bg-gray-200 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                  messages && 'opacity-100 bottom-0 text-[20px] ',
                )}
              >
                Messages
              </p>
            </Link>

            <div
              onClick={() => setProfileClicked(!profileClicked)}
              className={cn(
                'p-[14px] pl-5 w-full h-[60px] bg-gray-200 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                profile && 'opacity-100 bottom-0 text-[20px]',
              )}
            >
              Profile
              <div className="w-full h-min absolute top-[60px]  -ml-5">
                <Link to="/editProfile">
                  <p
                    className={cn(
                      'p-[14px] pl-5 w-full  bg-gray-300 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300 pointer-events-none',
                      editProfile && 'opacity-100 bottom-0 pointer-events-auto',
                    )}
                  >
                    Edit profile
                  </p>
                </Link>
                <Link to="/buyMembership">
                  <p
                    className={cn(
                      'p-[14px] pl-5 w-full  bg-gray-300 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                      buyMembership && 'opacity-100 bottom-0',
                    )}
                  >
                    Buy membership
                  </p>
                </Link>
                <Link to="/settings">
                  <p
                    className={cn(
                      'p-[14px] pl-5 w-full  bg-gray-300 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                      settings && 'opacity-100 bottom-0',
                    )}
                  >
                    Settings
                  </p>
                </Link>
                <Link to="/">
                  <p
                    className={cn(
                      'p-[14px] pl-5 w-full  bg-gray-300 hover:bg-[#bac2cc] cursor-pointer opacity-0 relative -bottom-3 duration-300',
                      logOut && 'opacity-100 bottom-0',
                    )}
                    onClick={handleSignOut}
                  >
                    Log out
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Nav;
