import React from 'react';

function PinaSoulmateSVG2() {
  const logo = document.querySelectorAll('#logoTwo path');
  console.log('logo', logo);

  for (let i = 0; i < logo.length; i++) {
    console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
  }

  return (
    <svg
      id="logoTwo"
      width="991"
      height="120"
      viewBox="0 0 991 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M986.203 81.44H988.007L988.192 79.6458C988.487 76.8015 988.635 74.0924 988.635 71.52C988.635 64.0238 986.984 57.2551 983.638 51.2654C980.385 45.174 975.7 40.3929 969.621 36.948C963.523 33.4922 956.55 31.792 948.763 31.792C940.784 31.792 933.623 33.54 927.337 37.0876C921.133 40.5464 916.305 45.5366 912.869 52.0057C909.427 58.4848 907.739 65.9695 907.739 74.4C907.739 82.8405 909.479 90.3753 913.018 96.9482L913.018 96.9482L913.024 96.9596C916.554 103.414 921.42 108.44 927.608 111.991L927.62 111.998C933.9 115.543 940.964 117.296 948.763 117.296C958.515 117.296 966.828 114.852 973.571 109.846L973.579 109.84L973.587 109.834C980.268 104.773 984.747 98.2107 986.978 90.2005L987.684 87.664H985.051H970.939H969.517L969.05 89.007C967.653 93.0232 965.227 96.2312 961.732 98.6784C958.397 100.986 954.118 102.208 948.763 102.208C942.315 102.208 936.895 100.211 932.39 96.2421C928.479 92.6372 926.086 87.7574 925.32 81.44H986.203ZM968.076 57.0036L968.082 57.0138L968.088 57.0239C969.776 59.7391 970.777 62.9738 971.033 66.784H925.528C926.442 60.7617 928.812 56.1166 932.539 52.6972C936.855 48.8242 942.034 46.88 948.187 46.88C952.508 46.88 956.367 47.7779 959.802 49.5396L959.829 49.5535L959.857 49.5666C963.342 51.222 966.07 53.6894 968.076 57.0036Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M900.758 102.912V100.912H898.758H889.11C885.245 100.912 883.074 100.159 882.006 99.1722C880.944 98.0926 880.166 96.0184 880.166 92.4001V47.8881H898.758H900.758V45.8881V35.0881V33.0881H898.758H880.166V15.2161V13.2161H878.166H865.062H863.062V15.2161V33.0881H854.838H852.838V35.0881V45.8881V47.8881H854.838H863.062V92.4001C863.062 100.336 864.94 106.527 869.179 110.431C873.337 114.262 879.384 116 886.95 116H898.758H900.758V114V102.912Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M777.253 37.1044L777.247 37.1081L777.241 37.1119C771.245 40.6688 766.532 45.6977 763.106 52.1439C759.661 58.5293 757.973 65.9205 757.973 74.256C757.973 82.5039 759.665 89.9332 763.097 96.495C766.519 103.036 771.227 108.163 777.22 111.819C783.215 115.478 789.915 117.296 797.269 117.296C804.384 117.296 810.696 115.74 816.135 112.558L816.145 112.553C819.531 110.546 822.419 108.209 824.789 105.537V114V116H826.789H840.037H842.037V114V35.088V33.088H840.037H826.789H824.789V35.088V43.2494C822.496 40.6845 819.7 38.4463 816.423 36.5296C810.979 33.3456 804.618 31.792 797.413 31.792C790.07 31.792 783.335 33.5568 777.253 37.1044ZM812.374 50.4866L812.398 50.5008L812.423 50.5144C816.199 52.6221 819.201 55.6976 821.432 59.803C823.646 63.8763 824.789 68.7201 824.789 74.4C824.789 80.0749 823.648 84.9719 821.428 89.1487C819.195 93.254 816.188 96.3826 812.399 98.5869C808.687 100.706 804.57 101.776 800.005 101.776C795.444 101.776 791.33 100.708 787.621 98.5922C783.929 96.3911 780.964 93.2626 778.727 89.1488C776.509 84.9764 775.365 80.0328 775.365 74.256C775.365 68.5761 776.509 63.7323 778.723 59.659C780.96 55.5416 783.923 52.465 787.606 50.3605C791.319 48.2386 795.438 47.168 800.005 47.168C804.557 47.168 808.665 48.279 812.374 50.4866Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M743.374 116H745.374V114V67.488C745.374 59.9414 743.934 53.4008 740.952 47.9519C737.995 42.5471 733.929 38.4611 728.758 35.7687C723.635 33.005 717.833 31.6479 711.406 31.6479C704.82 31.6479 698.796 33.2739 693.38 36.5438C689.126 39.1121 685.799 42.5067 683.416 46.7007C680.854 42.36 677.416 38.9078 673.104 36.3856C667.673 33.209 661.46 31.6479 654.526 31.6479C648.957 31.6479 643.8 32.8147 639.087 35.1711L639.08 35.1751L639.072 35.1791C636.244 36.6245 633.756 38.4572 631.614 40.6707V35.088V33.088H629.614H616.51H614.51V35.088V114V116H616.51H629.614H631.614V114V70.512C631.614 62.5603 633.555 56.837 637.124 53.0126L637.131 53.0046L637.139 52.9965C640.737 49.0555 645.593 47.0239 651.934 47.0239C658.094 47.0239 662.751 48.9664 666.15 52.7053L666.166 52.7229L666.182 52.7401C669.647 56.3741 671.534 61.804 671.534 69.36V114V116H673.534H686.494H688.494V114V70.512C688.494 62.5603 690.435 56.837 694.004 53.0126L694.011 53.0046L694.019 52.9965C697.617 49.0555 702.473 47.0239 708.814 47.0239C714.974 47.0239 719.631 48.9664 723.03 52.7053L723.046 52.7229L723.062 52.7401C726.527 56.3741 728.414 61.804 728.414 69.36V114V116H730.414H743.374Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M596.176 7.43994V5.43994H594.176H581.072H579.072V7.43994V114V116H581.072H594.176H596.176V114V7.43994Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M560.947 35.088V33.088H558.947H545.843H543.843V35.088V78.432C543.843 86.3837 541.902 92.107 538.333 95.9314C534.749 99.7709 529.801 101.776 523.235 101.776C516.856 101.776 512.048 99.8627 508.562 96.2122C505.082 92.4715 503.203 87.0272 503.203 79.584V35.088V33.088H501.203H488.243H486.243V35.088V81.312C486.243 88.8586 487.683 95.3992 490.665 100.848L490.671 100.861L490.679 100.873C493.736 106.274 497.895 110.395 503.147 113.176L503.161 113.183L503.175 113.19C508.384 115.845 514.221 117.152 520.643 117.152C526.301 117.152 531.508 115.988 536.225 113.629L536.233 113.625L536.241 113.621C539.156 112.133 541.694 110.295 543.843 108.108V114V116H545.843H558.947H560.947V114V35.088Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M410.126 111.998L410.138 112.004C416.504 115.541 423.562 117.296 431.27 117.296C439.069 117.296 446.219 115.543 452.677 112.011C459.146 108.473 464.258 103.46 467.991 97.0051C471.838 90.4219 473.734 82.8662 473.734 74.4C473.734 65.9464 471.892 58.4422 468.143 51.9532C464.509 45.4972 459.492 40.5242 453.11 37.0778C446.744 33.5432 439.64 31.792 431.846 31.792C424.051 31.792 416.948 33.5432 410.581 37.0778C404.193 40.5277 399.13 45.5519 395.404 52.0986L395.404 52.0986L395.399 52.1075C391.751 58.5917 389.958 66.0426 389.958 74.4C389.958 82.8405 391.697 90.3753 395.237 96.9482L395.245 96.9644L395.254 96.9805C398.884 103.432 403.846 108.452 410.126 111.998ZM452.729 89.5156L452.721 89.5297L452.713 89.5439C450.387 93.6584 447.34 96.6863 443.574 98.7004C439.738 100.752 435.646 101.776 431.27 101.776C426.884 101.776 422.897 100.796 419.272 98.8546C415.713 96.8539 412.808 93.8416 410.571 89.732C408.458 85.6751 407.35 80.5933 407.35 74.4C407.35 68.3124 408.502 63.2832 410.707 59.227C412.945 55.1092 415.849 52.1476 419.399 50.2423L419.407 50.2383L419.414 50.2342C423.139 48.1947 427.222 47.168 431.702 47.168C436.078 47.168 440.115 48.1913 443.845 50.2342L443.861 50.2431L443.878 50.2517C447.53 52.1649 450.528 55.1358 452.857 59.2561C455.146 63.3058 456.342 68.3241 456.342 74.4C456.342 80.4639 455.102 85.4716 452.729 89.5156Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M325.466 113.367L325.486 113.376L325.506 113.385C331.073 115.814 337.304 117.008 344.162 117.008C351.335 117.008 357.668 115.612 363.096 112.744C368.46 109.911 372.592 106.169 375.397 101.493C378.17 96.8716 379.57 91.9093 379.57 86.6399C379.57 80.0217 378.067 74.5438 374.828 70.435C371.762 66.4518 368.047 63.4975 363.688 61.6256C359.528 59.7453 354.098 57.8923 347.434 56.057C342.008 54.5338 337.812 53.2125 334.821 52.0929C332.125 50.957 329.839 49.3566 327.94 47.2899C326.312 45.4198 325.426 42.9472 325.426 39.6959C325.426 35.1134 326.953 31.7913 329.865 29.4454L329.878 29.435L329.89 29.4244C332.914 26.9049 336.893 25.5679 342.002 25.5679C347.515 25.5679 351.724 26.9632 354.83 29.565L354.842 29.5753L354.854 29.5855C358.083 32.2034 359.773 35.124 360.177 38.36L360.396 40.1119H362.162H376.562H378.769L378.552 37.9154C377.732 29.6085 374.059 22.8657 367.572 17.818C361.19 12.7765 353.042 10.3359 343.298 10.3359C336.533 10.3359 330.436 11.5305 325.049 13.97L325.042 13.973L325.036 13.976C319.736 16.4222 315.547 19.9211 312.54 24.4833C309.523 29.0608 308.034 34.2981 308.034 40.1279C308.034 46.6541 309.49 52.1085 312.605 56.2973L312.622 56.3205L312.64 56.3432C315.706 60.2269 319.37 63.129 323.63 64.9992L323.638 65.0028L323.646 65.0062C327.805 66.7884 333.236 68.5451 339.907 70.2855C345.246 71.7159 349.435 73.0368 352.501 74.2448C355.393 75.3842 357.764 77.0309 359.653 79.1772C361.381 81.1417 362.322 83.7596 362.322 87.2159C362.322 91.5406 360.743 95.0182 357.541 97.8002C354.457 100.466 350.081 101.92 344.162 101.92C337.952 101.92 333.522 100.418 330.558 97.7128C327.552 94.7838 325.847 91.244 325.416 87.0129L325.232 85.2159H323.426H309.458H307.423L307.458 87.251C307.561 93.0984 309.21 98.3406 312.431 102.912L312.437 102.921L312.444 102.93C315.644 107.369 320.009 110.841 325.466 113.367Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M227.41 37.1044L227.403 37.1081L227.397 37.1119C221.401 40.6688 216.688 45.6977 213.262 52.1439C209.818 58.5293 208.13 65.9205 208.13 74.256C208.13 82.5039 209.821 89.9332 213.253 96.495C216.675 103.036 221.383 108.163 227.376 111.819C233.371 115.478 240.072 117.296 247.426 117.296C254.54 117.296 260.852 115.74 266.291 112.558L266.301 112.553C269.687 110.546 272.575 108.209 274.946 105.537V114V116H276.946H290.194H292.194V114V35.088V33.088H290.194H276.946H274.946V35.088V43.2494C272.652 40.6845 269.856 38.4463 266.579 36.5296C261.136 33.3456 254.775 31.792 247.57 31.792C240.226 31.792 233.491 33.5568 227.41 37.1044ZM262.531 50.4866L262.554 50.5008L262.579 50.5144C266.355 52.6221 269.357 55.6976 271.588 59.803C273.802 63.8763 274.946 68.7201 274.946 74.4C274.946 80.0749 273.804 84.9719 271.584 89.1487C269.351 93.254 266.344 96.3826 262.555 98.5869C258.844 100.706 254.727 101.776 250.162 101.776C245.6 101.776 241.486 100.708 237.777 98.5922C234.086 96.3911 231.12 93.2626 228.883 89.1488C226.666 84.9764 225.522 80.0328 225.522 74.256C225.522 68.5761 226.665 63.7323 228.879 59.659C231.116 55.5416 234.079 52.465 237.762 50.3605C241.475 48.2386 245.594 47.168 250.162 47.168C254.714 47.168 258.822 48.279 262.531 50.4866Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M193.62 116H195.62V114V67.488C195.62 56.1869 192.54 47.2468 186.089 41.0005C179.707 34.7247 171.401 31.6479 161.364 31.6479C155.705 31.6479 150.458 32.8123 145.653 35.1635L145.645 35.1673L145.638 35.1711C142.739 36.6203 140.197 38.4439 138.02 40.6392V35.088V33.088H136.02H122.916H120.916V35.088V114V116H122.916H136.02H138.02V114V70.368C138.02 62.4222 139.958 56.7015 143.522 52.8771C147.212 49.0204 152.174 47.0239 158.628 47.0239C164.998 47.0239 169.804 48.9788 173.293 52.7234L173.301 52.7318L173.309 52.7401C176.774 56.3741 178.66 61.804 178.66 69.36V114V116H180.66H193.62Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M102.583 35.088V33.088H100.583H87.4786H85.4786V35.088V114V116H87.4786H100.583H102.583V114V35.088ZM86.4964 21.0942C88.6084 23.2062 91.2329 24.272 94.2466 24.272C97.1812 24.272 99.7181 23.1928 101.729 21.074C103.828 18.9655 104.887 16.3481 104.887 13.344C104.887 10.3399 103.828 7.7225 101.729 5.61402C99.7181 3.49528 97.1812 2.41602 94.2466 2.41602C91.2329 2.41602 88.6084 3.48184 86.4964 5.59381C84.3844 7.70577 83.3186 10.3303 83.3186 13.344C83.3186 16.3577 84.3844 18.9823 86.4964 21.0942Z"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M63.9416 65.3265L63.9421 65.3261C70.1101 59.3636 73.192 51.8764 73.192 43.0081C73.192 33.8544 70.0143 26.2514 63.6236 20.3732C57.2682 14.4382 48.1096 11.6321 36.488 11.6321H4.08801H2.08801V13.6321V114V116H4.08801H17.192H19.192V114V74.0961H36.488C48.4693 74.0961 57.7575 71.301 63.9416 65.3265ZM51.2646 55.0698C48.336 57.7543 43.5512 59.2961 36.488 59.2961H19.192V26.4321H36.488C43.346 26.4321 48.116 27.9324 51.1579 30.5838C54.1472 33.1893 55.8 37.2013 55.8 43.0081C55.8 48.4531 54.2086 52.3711 51.2646 55.0698Z"
        stroke="white"
        stroke-width="4"
      />
    </svg>
  );
}

export default PinaSoulmateSVG2;
