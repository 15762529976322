import { useEffect, useState } from 'react';

export const Countdown = ({ timestamp }) => {
  const [timer, setTimer] = useState(0);

  // refresh the timer every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getTimeUntilReset = () => {
    if (!timestamp) return '24:00:00'; // 24 hours

    const elapsed = Date.now() - timestamp;
    const remaining = 24 * 60 * 60 * 1000 - elapsed;

    const hours = Math.floor(remaining / (60 * 60 * 1000));
    const minutes = Math.floor((remaining % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((remaining % (60 * 1000)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const countdown = getTimeUntilReset();

  return (
    <>
      {countdown}
      <div className="hidden">{timer}</div>
    </>
  );
};
