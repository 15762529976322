import React, { useCallback, useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import {
  getStorage,
  ref as refStorage,
  list,
  getDownloadURL,
} from 'firebase/storage';
import { cn } from '../utils/cn';
import UserCard from './UserCard';

function Lists() {
  const [currentTab, setCurrentTab] = useState('My Likes');
  const [userUID, setUserUID] = useState('');
  const [likesObjects, setLikesObjects] = useState([]);
  const [favoritesObjects, setFavoritesObjects] = useState([]);
  const [likedByObjects, setLikedByObjects] = useState([]);
  const [favoritedByObjects, setFavoritedByObjects] = useState([]);
  const [matchesObjects, setMatchesObjects] = useState([]);
  const auth = getAuth();
  const database = getDatabase();
  const [imagesUrls, setImagesUrls] = useState({});

  const buttonLabels = [
    'My Likes',
    'My Favorites',
    'Liked Me',
    'Favorited Me',
    'Matches',
  ];
  const fetchUsers = useCallback(
    (uids) => {
      const promises = uids.map((uid) => {
        return new Promise((resolve) => {
          const individualUserRef = ref(database, `users/${uid}`);
          onValue(individualUserRef, (userSnapshot) => {
            resolve(userSnapshot.val());
          });
        });
      });
      return Promise.all(promises);
    },
    [database],
  );

  const fetchProfileImage = async (uid) => {
    const savedImageUrl = localStorage.getItem(`personImage_${uid}`);
    if (savedImageUrl) {
      return savedImageUrl;
    }

    const storage = getStorage();
    const userImagesRef = refStorage(storage, `images/${uid}`);

    try {
      const images = await list(userImagesRef);
      if (images.items.length > 0) {
        const imageUrl = await getDownloadURL(images.items[0]);
        localStorage.setItem(`personImage_${uid}`, imageUrl);
        return imageUrl;
      }
    } catch (error) {
      console.error('Error fetching profile image for user', uid, ':', error);
    }
    return null;
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserUID(user.uid);
    }
  }, [auth]);

  useEffect(() => {
    (async () => {
      const newImageUrls = {};

      const masterObject = [
        ...likesObjects,
        ...favoritesObjects,
        ...favoritedByObjects,
        ...matchesObjects,
      ];

      for (let person of masterObject) {
        if (person)
          newImageUrls[person.id] = await fetchProfileImage(person.id);
      }

      setImagesUrls(newImageUrls);
    })();
  }, [likesObjects, favoritesObjects, favoritedByObjects, matchesObjects]);

  useEffect(() => {
    if (userUID) {
      const userRef = ref(database, `users/${userUID}`);
      onValue(userRef, async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          switch (currentTab) {
            case 'My Likes':
              if (data.likes) {
                const filteredLikes = data.likes.filter((uid) => uid !== '-1');

                const userObjects = await fetchUsers(filteredLikes);
                setLikesObjects(userObjects);
              }
              break;
            case 'My Favorites':
              if (data.favorites) {
                const userObjects = await fetchUsers(data.favorites);
                setFavoritesObjects(userObjects);
              }
              break;
            case 'Liked Me':
              if (data.likedBy) {
                const userObjects = await fetchUsers(data.likedBy);
                setLikedByObjects(userObjects);
              }
              break;
            case 'Favorited Me':
              if (data.favoritedBy) {
                const userObjects = await fetchUsers(data.favoritedBy);
                setFavoritedByObjects(userObjects);
              }
              break;
            case 'Matches':
              if (
                data.likes &&
                data.favorites &&
                data.likedBy &&
                data.favoritedBy
              ) {
                const likedAndFavoritedMe = [
                  ...data.likedBy,
                  ...data.favoritedBy,
                ];
                const iLikedAndFavorited = [...data.likes, ...data.favorites];

                const matches = likedAndFavoritedMe.filter((uid) =>
                  iLikedAndFavorited.includes(uid),
                );

                // Remove duplicate UIDs and "-1" placeholders
                const uniqueMatches = [...new Set(matches)].filter(
                  (uid) => uid !== '-1',
                );

                const matchedUsers = await fetchUsers(uniqueMatches);
                setMatchesObjects(matchedUsers);
              }
              break;

            default:
              break;
          }
        }
      });
    }
  }, [userUID, database, currentTab, fetchUsers]);

  return (
    <>
      <Nav />
      <div className="w-full min-h-screen h-min bg-[#EAE0C8] overflow-hidden text-[#374756]">
        <div className="max-w-[1350px] w-full sm:w-[90%] h-screen overflow-auto p-10 bg-white flex flex-wrap mx-auto justify-center md:justify-start content-start ">
          <div className="mt-[50px] md:mt-[150px]">
            {buttonLabels.map((label) => (
              <button
                key={label}
                className={cn(
                  'h-[48px] w-1/2 md:w-[192px] duration-150 bg-[#F0C7CD] text-start border-[1px] border-white pl-3 hover:bg-[#9b111e] hover:text-white',
                  currentTab === label && 'bg-[#9b111e] text-white', // Only one condition now
                )}
                onClick={() => setCurrentTab(label)}
              >
                {label}
              </button>
            ))}
          </div>
          {/* maps */}
          <div className="w-full mt-10 ">
            {currentTab === 'My Likes' &&
              (likesObjects?.length > 0 ? (
                likesObjects.map(({ id, name }) => (
                  <UserCard
                    key={id}
                    id={id}
                    name={name}
                    profilePicture={imagesUrls[id]}
                  />
                ))
              ) : (
                <div>You havent liked anyone yet</div>
              ))}

            {currentTab === 'My Favorites' &&
              (favoritesObjects?.length > 1 ? (
                favoritesObjects.map((person) => {
                  if (!person) return null;
                  return (
                    <UserCard
                      key={person.id}
                      id={person.id}
                      name={person.name}
                      profilePicture={imagesUrls[person.id]}
                    />
                  );
                })
              ) : (
                <div>You havent favorited anyone yet</div>
              ))}

            {currentTab === 'Liked Me' &&
              (likedByObjects?.length > 1 ? (
                likedByObjects.map((person) => {
                  if (!person) return null;
                  return (
                    <UserCard
                      key={person.id}
                      id={person.id}
                      name={person.name}
                      profilePicture={imagesUrls[person.id]}
                    />
                  );
                })
              ) : (
                <div>No likes yet</div>
              ))}

            {currentTab === 'Favorited Me' &&
              (favoritedByObjects?.length > 1 ? (
                favoritedByObjects.map((person) => {
                  if (!person) return null;
                  return (
                    <UserCard
                      key={person.id}
                      id={person.id}
                      name={person.name}
                      profilePicture={imagesUrls[person.id]}
                    />
                  );
                })
              ) : (
                <div>No favorites yet</div>
              ))}

            {currentTab === 'Matches' &&
              (matchesObjects?.length > 0 ? (
                matchesObjects.map((person) => {
                  if (!person) return null;
                  return (
                    <UserCard
                      key={person.id}
                      id={person.id}
                      name={person.name}
                      profilePicture={imagesUrls[person.id]}
                    />
                  );
                })
              ) : (
                <div>No matches yet</div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Lists;
