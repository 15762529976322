import React, { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, update, onValue } from 'firebase/database';
import { api } from '../utils/api';
import { cn } from '../utils/cn';
import { AiFillGold } from 'react-icons/ai';
import { RiVipDiamondFill } from 'react-icons/ri';
import { BiSolidWinkSmile } from 'react-icons/bi';
import useUserStore from '../hooks/useUserStore';

const BuyMembership = () => {
  const [billing, setBilling] = React.useState('free');
  const { user } = useUserStore((state) => ({ user: state.user }));
  const userId = user.uid;
  const [endMembershipCard, setEndMembershipCard] = useState(false);

  const handleEndMembership = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    const db = getDatabase();

    setEndMembershipCard(false);

    try {
      if (!user) return console.log('No user authenticated');

      // Retrieve the subscriptionId for the current user.
      const userRef = ref(db, `users/${userId}`);

      const url = `/cancel-subscription`;
      const data = { userId };
      const call = { method: 'POST', url, data };
      const response = await api(call);

      if (!response.data.success)
        alert('Error ending membership. Please try again.');

      alert('Membership ended successfully!');
      await update(userRef, { subscriptionId: '' });
      await update(userRef, { billing: 'free' });
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (userId) {
      const db = getDatabase();
      const userRef = ref(db, `users/${userId}/billing`);

      const unsubscribe = onValue(
        userRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setBilling(snapshot.val());
          } else {
            console.log('No user data found!');
          }
        },
        (error) => {
          console.error('Error fetching user data:', error);
        },
      );

      // Cleanup function to unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [userId]);

  const isFree = billing === 'free';
  const isGold = billing === 'gold';
  const isDiamond = billing === 'diamond';

  const cardClasses =
    'flex flex-col w-full xl:w-[350px] rounded-md justify-between';

  return (
    <>
      <Nav />
      {endMembershipCard && (
        <div className="fixed w-[200px] h-[100px] md:w-[400px] md:h-[130px] text-center py-3 bg-white rounded-lg shadow-lg border-2 z-50 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <p className="mt-4">Do you want to end your membership?</p>
          <div className="w-[40%] mx-auto mt-3 flex justify-between">
            <button
              onClick={handleEndMembership}
              className="px-2 border-[2px] border-red-500 rounded-md hover:bg-red-100"
            >
              Yes
            </button>
            <button
              onClick={() => setEndMembershipCard(false)}
              className="px-2 border-[2px] border-green-500 rounded-md hover:bg-green-100"
            >
              No
            </button>
          </div>
        </div>
      )}
      <div className="w-full min-h-screen h-min bg-[#EAE0C8] overflow-auto  text-[#374756]">
        <div className="max-w-[1350px] w-full sm:w-[90%] min-h-screen bg-white flex flex-col mx-auto justify-center md:justify-start content-start">
          <div
            className={cn(
              'w-full h-[50px] mt-[60px] lg:mt-[128px] bg-red-400 text-center px-5 text-white grid place-content-center',
              !isFree && 'opacity-0 pointer-events-none h-0',
            )}
          >
            Get more Likes and Favorites by becoming a Gold or Diamond Member
          </div>
          <div className="mx-auto my-2">
            you are currently a <span className="font-semibold">{billing}</span>{' '}
            member
          </div>
          <div className="grid grid-cols-1 gap-5 p-5 lg:grid-cols-3 justify-items-center">
            {/* free */}
            <div
              className={cn(
                cardClasses,
                'bg-pink-200 text-pink-700',
                isFree && 'outline-pink-500 outline-8 outline',
              )}
            >
              <div>
                <div className="flex gap-3 justify-center items-center m-5 pb-5 border-b-[1px] border-pink-600">
                  <BiSolidWinkSmile className="w-8 h-8 text-pink-400" />
                  <p className="text-[20px]">Free membership</p>
                </div>
                <ul>
                  <li className="w-[90%] mx-auto p-5 pt-0 text-[18px]">
                    • 5 likes / day
                  </li>
                  <li className="w-[90%] mx-auto px-5 pb-8 text-[18px]">
                    • 1 favorite / day
                  </li>
                </ul>
              </div>
              <button
                className="w-[90%] h-min p-2 bg-pink-300 grid place-content-center mx-auto m-5 mt-0 rounded-md hover:bg-pink-400 duration-150 disabled:opacity-50 disabled:pointer-events-none"
                disabled={isFree}
                onClick={() => setEndMembershipCard(true)}
              >
                Free
              </button>
            </div>

            {/* gold */}
            <div
              className={cn(
                cardClasses,
                'bg-amber-200 text-amber-700',
                isGold && 'outline-amber-500 outline-8 outline',
              )}
            >
              <div>
                <div className="flex gap-3 justify-center items-center m-5 pb-5 border-b-[1px] border-amber-600">
                  <AiFillGold className="w-8 h-8 text-amber-500" />
                  <p className="text-[20px]">Gold membership</p>
                </div>
                <ul>
                  <li className="w-[90%] mx-auto p-5 pt-0 text-[18px]">
                    • 25 likes / day
                  </li>
                  <li className="w-[90%] mx-auto px-5 pb-5 text-[18px]">
                    • 5 favorites / day
                  </li>
                  <li className="w-[90%] mx-auto px-5 pb-8 text-[18px]">
                    • Gold outline on profile
                  </li>
                </ul>
              </div>
              <Link
                to="/checkout/gold"
                className={cn(isGold && 'pointer-events-none')}
              >
                <button
                  className="w-[90%] h-min p-2 bg-amber-300 grid place-content-center mx-auto m-5 mt-0 rounded-md hover:bg-amber-400 duration-150 disabled:opacity-50"
                  disabled={isGold}
                >
                  $ 9.99 USD per month
                </button>
              </Link>
            </div>

            {/* diamond */}
            <div
              className={cn(
                cardClasses,
                'bg-sky-200 text-sky-700',
                isDiamond && 'outline-sky-500 outline-8 outline',
              )}
            >
              <div>
                <div className="flex gap-3 justify-center items-center m-5 pb-5 border-b-[1px] border-sky-600">
                  <RiVipDiamondFill className="w-8 h-8 text-sky-400" />
                  <p className="text-[20px]">Diamond membership</p>
                </div>
                <ul>
                  <li className="w-[90%] mx-auto p-5 pt-0 text-[18px]">
                    • Unlimited likes / day
                  </li>
                  <li className="w-[90%] mx-auto px-5 pb-5 text-[18px]">
                    • Unlimited favorites / day
                  </li>
                  <li className="w-[90%] mx-auto px-5 pb-8 text-[18px]">
                    • Diamond outline on profile
                  </li>
                </ul>
              </div>
              <Link
                to="/checkout/diamond"
                className={cn(isDiamond && 'pointer-events-none')}
              >
                <button
                  className="w-[90%] h-min p-2 bg-sky-300 grid place-content-center mx-auto m-5 mt-0 rounded-md hover:bg-sky-400 duration-150 disabled:opacity-50 disabled:pointer-events-none"
                  disabled={isDiamond}
                >
                  $ 14.99 USD per month
                </button>
              </Link>
            </div>
          </div>
          {!isFree && (
            <button onClick={() => setEndMembershipCard(true)}>
              End Membership
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default BuyMembership;
