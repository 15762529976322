import { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { cn } from '../utils/cn';
import Loader from '../components/Loader';
import Nav from '../components/Nav';
import { RiSendPlaneFill } from 'react-icons/ri';
import { useGetParticipants } from '../hooks/useGetParticipants';
import { useGetConversation } from '../hooks/useGetConversation';
import {
  getDatabase,
  ref,
  push,
  serverTimestamp,
  update,
  get,
} from 'firebase/database';
import { useReceiverOnlineStatus } from '../hooks/useReceiverOnlineStatus';
import LastOnline from '../components/LastOnline';

const Messages = () => {
  const [message, setMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);

  const params = useParams();
  const { conversationId } = params;

  const { sender, receiver, loadingParticipants } = useGetParticipants(params);
  const { messages, permitted, loadingConversation } =
    useGetConversation(params);
  const receiverStatus = useReceiverOnlineStatus(params);

  const scrollableDivRef = useRef(null);

  const scrollToBottom = () => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, message, loadingParticipants]);

  useEffect(() => {
    // resets the unread messages count to 0
    const resetUnreadMessages = async () => {
      if (!sender || !receiver || !conversationId) return;
      if (!sender.id || !receiver.id) return;

      try {
        const db = getDatabase();
        const updates = {};
        updates[`users/${sender.id}/unreadMessages/${receiver.id}`] = 0;

        await update(ref(db), updates);
      } catch (error) {
        console.error('Error resetting unread messages:', error);
      }
    };

    resetUnreadMessages();
  }, [sender, receiver, conversationId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      weekday: 'long', // long, short, narrow
      year: 'numeric', // numeric, 2-digit
      month: 'long', // numeric, 2-digit, long, short, narrow
      day: 'numeric', // numeric, 2-digit
      hour: 'numeric', // numeric, 2-digit
      minute: '2-digit', // numeric, 2-digit
      hour12: true, // Use 12-hour format
    });
  };

  const isSameDay = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  let lastTimestamp = null;

  const sendMessage = async () => {
    if (!conversationId || !sender.id || !message) return;

    if (message.length > 300) {
      alert('Your message is too long. Please limit it to 300 characters.');
      return;
    }

    setSendingMessage(true);
    const db = getDatabase();

    // Create a reference for a new message ID
    const newMessageRef = push(ref(db, `messages/${conversationId}/messages`));
    const newMessageId = newMessageRef.key;

    const messageObject = {
      timestamp: serverTimestamp(), // Firebase server timestamp
      senderUserId: sender.id,
      content: message,
    };

    // Prepare the update for the message
    const messageUpdates = {};
    messageUpdates[`messages/${conversationId}/messages/${newMessageId}`] =
      messageObject;

    try {
      // Execute the update to add the new message
      await update(ref(db), messageUpdates);

      if (!receiverStatus.isInConversation) {
        // If the receiver is not in the chatroom, increment the unread message count
        const unreadUpdates = {};
        const unreadRef = ref(
          db,
          `users/${receiver.id}/unreadMessages/${sender.id}`,
        );

        // Fetch the current count
        const snapshot = await get(unreadRef);
        let unreadCount = snapshot.exists() ? snapshot.val() : 0;

        // Increment the count or set it to 1 if it doesn't exist
        unreadCount += 1;
        unreadUpdates[`users/${receiver.id}/unreadMessages/${sender.id}`] =
          unreadCount;

        // Update the unread message count
        await update(ref(db), unreadUpdates);
      }

      setMessage(''); // Clear the message input after sending
    } catch (error) {
      console.error('Error sending message:', error);
    }

    setSendingMessage(false);
  };

  const handleEnterOnTextArea = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents the default action (inserting a newline)
      sendMessage();
    }
  };

  if (loadingParticipants)
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Loader size={30} />
      </div>
    );

  if (!permitted) return <>Permission denied</>;

  const inChatroomClasses = cn(
    'w-[10px] h-[10px] rounded-full',
    receiverStatus.isInConversation ? 'bg-[#4CAF50]' : 'bg-orange-500',
  );

  // Render the conversation data
  return (
    <>
      <Nav />
      <div className="w-full min-h-screen h-min bg-[#EAE0C8] overflow-hidden  text-[#374756]">
        <div className="max-w-[1350px] w-full sm:w-[90%] h-screen overflow-auto bg-white flex flex-wrap mx-auto justify-center md:justify-start content-start relative ">
          {/* header */}
          <div className="max-w-[1350px] w-full sm:w-[90%] h-[132px] bg-[#efefef] fixed top-[60px] lg:top-[130px] flex items-center">
            {/* image */}

            <Link to={`/user/${receiver?.id}`} className="ml-[5%]">
              <img
                src={receiver?.profilePhoto}
                alt={receiver?.name}
                className="w-[81px] h-[81px] rounded-full object-cover object-center cursor-pointer bg-gray-400"
              />
            </Link>

            {/* desktop */}
            <div className="hidden md:block">
              <div className=" w-max ml-10 h-[76px] flex flex-wrap content-start">
                <p className="text-[24px] w-full">{receiver?.name}</p>
                <p className="text-[21px] whitespace-pre">
                  {receiver?.age}, {receiver?.location}
                </p>
              </div>
              <div className="absolute right-[5%] top-1/2 tranform translate-y-[-50%] flex flex-col gap-2 items-end ">
                <LastOnline userId={receiver?.id} className="text-[16px]">
                  <LastOnline.OnlineStatus />
                  <LastOnline.Indicator className="w-[10px] h-[10px]" />
                </LastOnline>
                <div
                  className={cn(
                    'flex gap-2 items-center',
                    receiverStatus.isInConversation
                      ? 'text-green-500'
                      : 'text-orange-500',
                  )}
                >
                  {receiverStatus.isInConversation
                    ? 'In chatroom'
                    : 'Outside of chatroom'}
                  <div className={inChatroomClasses} />
                </div>
              </div>
            </div>
            {/* mobile */}
            <div className="block md:hidden">
              <div className="ml-10 h-[76px] grid grid-rows-4 content-start text-[14px] gap-3">
                <p className="w-full">{receiver?.name}</p>
                <p className="whitespace-pre">
                  {receiver?.age}, {receiver?.location}
                </p>
                <p>
                  <LastOnline
                    userId={receiver?.id}
                    className="text-[12px] w-max"
                  >
                    <LastOnline.Indicator className="w-[10px] h-[10px]" />
                    <LastOnline.OnlineStatus />
                  </LastOnline>
                </p>
                <p
                  className={cn(
                    'flex gap-2 items-center text-[12px] w-max',
                    receiverStatus.isInConversation
                      ? 'text-green-500'
                      : 'text-orange-500',
                  )}
                >
                  <div className={inChatroomClasses} />
                  {receiverStatus.isInConversation
                    ? 'In chatroom'
                    : 'Outside of chatroom'}
                </p>
              </div>
            </div>
          </div>
          {/* holds messages */}
          <div className="fixed top-[193px] md:top-[263px] max-w-[1350px] w-full sm:w-[90%]">
            <div
              ref={scrollableDivRef}
              className="w-full h-[38vh] sm:h-[46vh] mb-[2vh] overflow-auto hide-scrollbar md:w-[90%] mx-auto relative"
            >
              {loadingConversation ? (
                <div className="flex items-center justify-center w-full m-5">
                  <Loader size={30} />
                </div>
              ) : (
                messages?.map((message) => {
                  const isCurrentUser = message.senderUserId === sender.id;
                  const showTimestamp =
                    !lastTimestamp ||
                    !isSameDay(lastTimestamp, message.timestamp);
                  if (showTimestamp) lastTimestamp = message.timestamp;

                  const messageClass = cn(
                    'text-[16px] w-max h-max max-w-full md:max-w-[45%] p-3 overflow-hidden',
                    isCurrentUser &&
                      'bg-[#9B111E] rounded-t-[13px] rounded-l-[13px] text-white self-end ',
                    !isCurrentUser &&
                      'bg-[#FFCFCC] rounded-r-[13px] rounded-b-[13px] self-start',
                  );
                  const timestampClass = cn('text-[10px] my-2 self-center');

                  return (
                    <div key={message.id} className="flex flex-col gap-2 m-5">
                      {showTimestamp && (
                        <div className={timestampClass}>
                          {formatTimestamp(message.timestamp)}
                        </div>
                      )}
                      <div className={messageClass}>{message.content}</div>
                    </div>
                  );
                })
              )}
            </div>
            <div className="relative w-[95%] md:w-[90%] mx-auto ">
              <textarea
                placeholder="Send message"
                className="w-full h-[170px] sm:h-[190px] rounded-[17px] border-[1px] border-black mb-10 p-3"
                autoFocus
                maxLength={300}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleEnterOnTextArea}
              />
              <span className="absolute text-sm opacity-50 bottom-5 right-5">
                {message.length} / 300
              </span>
              <button
                onClick={sendMessage}
                disabled={sendingMessage}
                className="absolute right-5 bottom-16 rounded-full w-[40px] h-[40px] bg-[#BF5860] flex items-center justify-center hover:bottom-[68px] duration-150 cursor-pointer disabled:opacity-50"
              >
                <RiSendPlaneFill className="w-[50%] h-[50%] relative left-[-1px] top-[1px] text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
