import React, { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import {
  getStorage,
  ref as refStorage,
  list,
  getDownloadURL,
} from 'firebase/storage';
import { AiFillMessage } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import { cn } from '../utils/cn';
import { ref, onValue, push, set } from 'firebase/database';
import useOpenConversation from '../hooks/useOpenConversation';
import Loader from '../components/Loader';
import { getDatabase, ref as dbRef, get } from 'firebase/database';
import { FavoritesPopup } from '../components/FavoritesPopup';
import FavoriteControls from './FavoriteControls';

function InfoRow({ label, value }) {
  return (
    <div className="grid grid-cols-12 border-b-[1px] p-1">
      <p className="col-span-4 text-[#99A5C1] ml-1">{label}</p>
      <p className="col-span-8 ">{value}</p>
    </div>
  );
}

function Person() {
  const { userId: personId } = useParams();

  const [person, setPerson] = useState();
  const [userPhotos, setUserPhotos] = useState([]);
  const [mainPhoto, setMainPhoto] = useState('');
  const [userData, setUserData] = useState({});
  const [moreClicked, setMoreClicked] = useState(false);
  const [reportClicked, setReportClicked] = useState(false);
  const [reportInfo, setReportInfo] = useState('');
  const [reportSubmitedSuccessfully, setReportSubmitedSuccessfully] =
    useState(false);

  const canMessage =
    userData?.likes?.includes(personId) ||
    userData?.favorites?.includes(personId);

  const auth = getAuth();

  const { openConversation, conversationLoading } = useOpenConversation();

  const [popupState, setPopupState] = useState(false);

  const handleMessageClick = async () => {
    if (canMessage) {
      await openConversation(personId);
    } else {
      alert('You must like or favorite this user before you can message them');
    }
  };

  const handleReportSubmit = (e) => {
    e.preventDefault();
    const personReportsRef = ref(db, `users/${personId}/reports`);

    const report = {
      timestamp: new Date().toString(),
      reason: reportInfo,
    };

    const newReportRef = push(personReportsRef);
    set(newReportRef, report)
      .then(() => {
        setReportSubmitedSuccessfully(true);
      })
      .catch((error) => {
        console.error('Error adding report:', error);
      });

    setReportInfo('');
    setMoreClicked(false);
  };

  useEffect(() => {
    let timer;
    if (reportSubmitedSuccessfully) {
      timer = setTimeout(() => {
        setReportSubmitedSuccessfully(false);
      }, 4000); // 5 seconds
    }

    // Cleanup function
    return () => {
      clearTimeout(timer);
    };
  }, [reportSubmitedSuccessfully]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = ref(db, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        const uData = snapshot.val();
        setUserData(uData);
      });
    }
  }, [auth]);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const storage = getStorage();
        const storageRef = refStorage(storage);
        const userFolderRef = refStorage(storageRef, `images/${personId}`);

        const urls = [];
        const images = await list(userFolderRef);
        for (let image of images.items) {
          const url = await getDownloadURL(image);
          urls.push(url);
        }

        setUserPhotos(urls);
        if (urls.length > 0) {
          setMainPhoto(urls[0]);
        }
      } catch (error) {
        console.error('Error fetching photos:', error);
      }
    };

    fetchPhotos();
  }, [personId]);

  const infoData = [
    { label: 'Name', value: person?.name },
    { label: 'Age', value: person?.age },
    { label: 'Gender', value: person?.gender },
    { label: 'Location', value: person?.location },
    { label: 'Has children', value: person?.children },
    { label: 'Seeking', value: person?.relationship },
    { label: 'Ethnicity', value: person?.ethnicity },
    { label: 'Religion', value: person?.religion },
  ];

  useEffect(() => {
    if (personId) {
      const fetchUserDetails = async () => {
        const db = getDatabase();
        const userRef = dbRef(db, `users/${personId}`);
        try {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setPerson(snapshot.val());
          } else {
            console.log('No user data found!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchUserDetails();
    }
  }, [personId]);

  if (!person)
    return (
      <div className="flex items-center justify-center w-full pt-12">
        <Loader size={30} />
      </div>
    );

  return (
    <>
      <Nav />
      <FavoritesPopup popupState={popupState} setPopupState={setPopupState} />
      <div className="w-full min-h-screen h-min bg-[#EAE0C8] overflow-hidden  text-[#374756]">
        <div className="max-w-[1350px] w-full sm:w-[90%] h-screen overflow-auto p-10 bg-white flex flex-wrap content-start mx-auto hide-scrollbar">
          <div className="w-full md:w-[80%] mx-auto h-min mt-[140px] text-[24px] md:text-[30px] flex flex-wrap relative">
            <p className="w-1/2 overflow-hidden ">{person?.name}</p>
            <div className="absolute right-0 flex items-center justify-center gap-2">
              <FavoriteControls {...{ userData, person }}>
                <FavoriteControls.Heart
                  handleMax={() => setPopupState('heart')}
                  className="w-[35px]"
                />
                <FavoriteControls.Favorite
                  handleMax={() => setPopupState('favorite')}
                  className="w-[35px]"
                />
              </FavoriteControls>
              <AiFillMessage
                className={cn(
                  'duration-150 cursor-pointer hover:text-orange-500',
                  conversationLoading && 'animate-pulse',
                  {
                    'text-orange-500': canMessage,
                    '': !canMessage,
                  },
                )}
                onClick={() => handleMessageClick()}
              />
              <BsThreeDots
                className={cn(
                  'duration-150 cursor-pointer hover:text-green-500',
                  moreClicked && 'text-green-500',
                )}
                onClick={() => setMoreClicked(!moreClicked)}
              />
              {moreClicked && (
                <div className="flex flex-wrap w-[200px] h-[35px] absolute bottom-[-40px] right-0 text-[16px]">
                  <button
                    className="w-full border-[2px]  duration-150 hover:bg-red-300 bg-red-200 border-red-300"
                    onClick={() => setReportClicked(!reportClicked)}
                  >
                    Report user
                  </button>
                  <form
                    className={cn(
                      'duration-300 w-full  bg-red-100 overflow-hidden flex flex-wrap content-start',
                      reportClicked
                        ? 'h-[200px] border-[2px] border-t-0 border-red-300'
                        : 'h-[0px] border-0',
                    )}
                    onSubmit={(e) => handleReportSubmit(e)}
                  >
                    <p className="w-full text-[14px] p-2">
                      Why would you like to report this user?
                    </p>
                    <textarea
                      placeholder="input report here."
                      required
                      value={reportInfo}
                      className="w-[90%] h-[100px] mx-auto text-[14px] border-[2px] bg-red-50 p-1 border-red-200 outline-red-300"
                      onChange={(e) => setReportInfo(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="w-[90%] h-min mx-auto mt-1 bg-red-50 border-[2px] border-red-200 hover:border-red-300 hover:bg-red-200 duration-150"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              )}
              {reportSubmitedSuccessfully && (
                <div className="w-max h-[35px] absolute bottom-[-40px] right-0 text-[16px] text-red-500">
                  Subbmitted report successfully
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-[80%] min mx-auto  mt-10 grid grid-cols-1 md:grid-cols-2">
            <div>
              <div className="flex flex-wrap w-full">
                <img
                  src={mainPhoto}
                  alt="User's profile"
                  className="w-full h-[300px] md:h-[250px] lg:h-[350px] xl:h-[450px] object-cover object-center cursor-pointer"
                />
              </div>
              <div className="flex flex-wrap justify-start mx-auto mt-2 w-max ">
                {userPhotos.slice(0, 5).map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt="User's uploaded self"
                    className="w-[50px] h-[50px] m-2 object-cover object-center cursor-pointer border-[1px]"
                    onClick={() => setMainPhoto(url)}
                  />
                ))}
              </div>
            </div>
            <div className="w-full h-min bg-[#F9F9F9] mt-3 border-[1px] text-[12px] md:ml-10 md:mt-0">
              {infoData.map((info, index) => (
                <InfoRow key={index} label={info.label} value={info.value} />
              ))}
            </div>
          </div>
          <div className="w-full h-min md:w-[80%] mx-auto mt-5 text-[14px] md:text-[16px] mb-24">
            <p className="w-full overflow-hidden">
              <span className="text-[#979797]">About me:</span>
              <br />
              {person?.aboutMe}
            </p>
            <p className="mt-5">
              <span className="text-[#979797] overflow-hidden">
                What I'm looking for:
              </span>
              <br />
              {person?.seeking}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Person;
