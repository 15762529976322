import { getDatabase, ref, set, serverTimestamp } from 'firebase/database';
import useUserStore from './useUserStore';

export const useSetOffline = () => {
  const userId = useUserStore((state) => state.userId);
  const clearUserId = useUserStore((state) => state.clearUserId);

  const setOffline = async () => {
    if (!userId) return;

    try {
      const db = getDatabase();
      const onlineStatusRef = ref(db, `users/${userId}/onlineStatus`);

      await set(onlineStatusRef, {
        online: false,
        page: 'offline',
        conversation: null,
        lastOnline: serverTimestamp(),
      });

      clearUserId(); // Clear userId from the store after setting offline
    } catch (error) {
      console.error('Error setting online status:', error);
    }
  };

  return setOffline;
};
