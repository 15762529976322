import React, { useState } from 'react';
import { cn } from '../utils/cn';

export const Checkbox = ({
  label,
  checked = false,
  onChange,
  disabled,
  className,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    if (onChange) {
      onChange(!isChecked);
    }
  };

  const wrapperClasses = cn(
    'flex items-center space-x-2 cursor-pointer',
    disabled && 'opacity-50 pointer-events-none',
    className,
  );

  const checkboxClasses = cn(
    'w-6 h-6 flex justify-center items-center rounded',
    'hover:border-gray-500 focus-within:border-gray-700 duration-150',
    isChecked ? 'bg-[#9b111e]' : 'bg-white border-2 border-gray-400',
  );

  return (
    <label className={wrapperClasses}>
      <div className={checkboxClasses}>
        <input
          type="checkbox"
          className="opacity-0 absolute"
          checked={isChecked}
          onChange={handleOnChange}
        />
        {isChecked && (
          <svg className="fill-current text-white w-4 h-4" viewBox="0 0 20 20">
            <path d="M7.629 14.571L3.357 10.3a1 1 0 00-1.414 1.414l4.95 4.95a1 1 0 001.414 0l8.586-8.586a1 1 0 10-1.414-1.414L7.629 14.571z" />
          </svg>
        )}
      </div>
      {label && <span className="select-none">{label}</span>}
    </label>
  );
};
