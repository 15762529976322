import { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  list,
  getDownloadURL,
} from 'firebase/storage';

export const useGetParticipants = (params) => {
  const [participants, setParticipants] = useState(null);
  const { senderId, receiverId } = params;

  const getProfilePicture = async (participant) => {
    try {
      const imagesRef = storageRef(getStorage(), `images/${participant.id}`);

      const images = await list(imagesRef);
      const hasImages = images.items.length > 0;
      if (!hasImages) return;

      const url = await getDownloadURL(images.items[0]);
      return url;
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  const getParticipants = async () => {
    try {
      const db = getDatabase();

      const snapshot = (snapshot) => snapshot.val();

      const senderRef = ref(db, `users/${senderId}`);
      const sender = await get(senderRef).then(snapshot);
      const senderProfilePic = await getProfilePicture(sender);
      sender.profilePhoto = senderProfilePic;

      const receiverRef = ref(db, `users/${receiverId}`);
      const receiver = await get(receiverRef).then(snapshot);
      const receiverProfilePic = await getProfilePicture(receiver);
      receiver.profilePhoto = receiverProfilePic;

      setParticipants({ sender, receiver });
    } catch (error) {
      console.log('failed to fetch participants', error);
    }
  };

  useEffect(() => {
    getParticipants();

    //eslint-disable-next-line
  }, []);

  return {
    sender: participants?.sender,
    receiver: participants?.receiver,
    loadingParticipants: !participants,
  };
};
