import { useCallback } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useSetOffline } from './useSetOffline';
import useUserStore from './useUserStore';

export const useSignOut = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const setOffline = useSetOffline();
  const setSigningOut = useUserStore((state) => state.setSigningOut);

  const handleSignOut = useCallback(async () => {
    if (!auth.currentUser) return;

    setSigningOut(true); // Indicate signing out

    // Perform sign-out logic
    try {
      await setOffline();
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    } finally {
      setSigningOut(false); // Reset the flag
    }
  }, [auth, navigate, setOffline, setSigningOut]);

  return handleSignOut;
};
