import { useGetUnreadMessages } from '../hooks/useGetUnreadMessages';
import { cn } from '../utils/cn';

const MessagesTab = () => {
  const unreadMessages = useGetUnreadMessages();
  const { totalMessages } = unreadMessages;

  const messagesBadgeClasses = cn(
    'absolute -top-2 -left-4 w-5 h-5 bg-white rounded-full text-red-500 text-xs flex items-center justify-center font-bold',
    !totalMessages ? 'hidden' : 'visible',
  );

  return (
    <div className="relative">
      <div className={messagesBadgeClasses}>{totalMessages}</div>
      <div>Messages</div>
    </div>
  );
};

export default MessagesTab;
