import React, { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { db } from '../firebase';
import { ref, onValue } from 'firebase/database';
import {
  getStorage,
  ref as refStorage,
  list,
  getDownloadURL,
} from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import UserFilter from '../components/FilterComponent';
import { cn } from '../utils/cn';
import {
  BsChevronRight,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronDoubleLeft,
} from 'react-icons/bs';
import LastOnline from '../components/LastOnline';
import { FavoriteControls } from './FavoriteControls';
import { FavoritesPopup } from '../components/FavoritesPopup';
import { DashboardStats } from '../components/DashboardStats';

function Dashboard() {
  const [arrayOfPeople, setArrayOfPeople] = useState([]);
  const [imagesUrls, setImagesUrls] = useState({});

  const [gender, setGender] = useState('');
  const [age, setAge] = useState({ min: 18, max: 100 });

  const [children, setChildren] = useState('');
  const [location, setLocation] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [relationship, setRelationship] = useState('');
  const [religion, setReligion] = useState('');

  const [userUID, setUserUID] = useState(null);
  const [userGender, setUserGender] = useState(null);

  const [userData, setUserData] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [popupState, setPopupState] = useState('hidden');

  const auth = getAuth();

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleChildrenChange = (event) => {
    setChildren(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleEthnicityChange = (event) => {
    setEthnicity(event.target.value);
  };
  const handleRelationshipChange = (event) => {
    setRelationship(event.target.value);
  };
  const handleReligionChange = (event) => {
    setReligion(event.target.value);
  };

  const filteredPeople = arrayOfPeople.filter((person) => {
    return (
      (gender === '' || gender === person.gender) &&
      age.min <= person.age &&
      person.age <= age.max &&
      (children === '' || children === person.children) &&
      (location === '' ||
        person.location.toLowerCase().includes(location.toLowerCase())) &&
      (ethnicity === '' || ethnicity === person.ethnicity) &&
      (relationship === '' || relationship === person.relationship) &&
      (religion === '' || religion === person.religion)
    );
  });
  const sortedPeople = filteredPeople.sort(
    (a, b) => b.lastActive - a.lastActive,
  );

  const totalPages = Math.ceil(sortedPeople?.length / itemsPerPage);

  const fetchProfileImage = async (uid) => {
    const savedImageUrl = localStorage.getItem(`personImage_${uid}`);
    if (savedImageUrl) {
      return savedImageUrl;
    }

    const storage = getStorage();
    const userImagesRef = refStorage(storage, `images/${uid}`);

    try {
      const images = await list(userImagesRef);
      if (images.items.length > 0) {
        const imageUrl = await getDownloadURL(images.items[0]);
        localStorage.setItem(`personImage_${uid}`, imageUrl);
        return imageUrl;
      }
    } catch (error) {
      console.error('Error fetching profile image for user', uid, ':', error);
    }
    return null;
  };

  // Implement next and previous functions
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const jumpToPage = (page) => {
    setCurrentPage(page);
  };

  // Get the current items to display
  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedPeople.slice(startIndex, endIndex);
  };

  const currentPeople = getCurrentItems();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserUID(user.uid);

      const userRef = ref(db, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData && userData.gender) {
          setUserGender(userData.gender);
          setGender(userGender === 'Male' ? 'Female' : 'Male');
        }
      });
    }
  }, [auth, userGender]);

  useEffect(() => {
    const usersRef = ref(db, 'users');

    // Subscribe to real-time updates
    const unsubscribe = onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setArrayOfPeople(Object.values(data));
      } else {
        setArrayOfPeople([]);
      }
    });

    // Clean up the subscription
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    (async () => {
      const newImageUrls = {};

      for (let person of arrayOfPeople) {
        newImageUrls[person.id] = await fetchProfileImage(person.id);
      }

      setImagesUrls(newImageUrls);
    })();
  }, [arrayOfPeople]);

  useEffect(() => {
    if (userUID) {
      const currentUserRef = ref(db, `users/${userUID}`);
      onValue(currentUserRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setUserData(userData);
        }
      });
    }
  }, [userUID]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Nav />
      <FavoritesPopup popupState={popupState} setPopupState={setPopupState} />

      <div className="w-full min-h-[100dvh] h-min bg-[#EAE0C8] text-[#374756]">
        <div className="max-w-[1350px] w-full sm:w-[90%] min-h-[100dvh]  bg-white flex flex-wrap mx-auto justify-center md:justify-start content-start pt-[60px] lg:pt-[128px]">
          <DashboardStats userData={userData} />

          <UserFilter
            gender={gender}
            handleGenderChange={handleGenderChange}
            userUID={userUID}
            age={age}
            setAge={setAge}
            children={children}
            handleChildrenChange={handleChildrenChange}
            location={location}
            handleLocationChange={handleLocationChange}
            ethnicity={ethnicity}
            handleEthnicityChange={handleEthnicityChange}
            relationship={relationship}
            handleRelationshipChange={handleRelationshipChange}
            religion={religion}
            handleReligionChange={handleReligionChange}
            filteredPeople={filteredPeople}
          />

          {/* members */}
          {screenWidth >= 640 ? (
            <div className="flex flex-wrap w-full m-10 h-max">
              {currentPeople?.map((person) => {
                return (
                  <div
                    key={person.id}
                    className={cn(
                      'w-[110px] h-[190px] md:w-[190px] md:h-[320px] border-[1px] m-2',
                      person.billing === 'gold' &&
                        'border-[2px] border-amber-300',
                      person.billing === 'diamond' &&
                        'border-[2px] border-sky-300',
                    )}
                  >
                    <Link to={`/user/${person.id}`}>
                      <img
                        src={imagesUrls[person.id]}
                        alt={`${person.name}'s profile`}
                        className="w-full h-[100px] md:h-[190px] object-cover object-center cursor-pointer"
                      />
                    </Link>

                    <div className="w-full h-[75px] md:h-[110px] p-1 md:p-2 flex flex-wrap">
                      <p className="text-[12px] md:text-[17px] w-full max-h-6 overflow-hidden">
                        {person.name} <span className="text-green-500"></span>
                      </p>
                      <LastOnline
                        className="gap-1 text-[11px]"
                        userId={person.id}
                      >
                        <LastOnline.Indicator className="w-[6px] h-[6px]" />
                        <LastOnline.OnlineStatus className="mt-0.5" />
                      </LastOnline>
                      <p className="text-[9px] md:text-[11px] w-full relative">
                        {person.age}
                      </p>
                      <p className="text-[9px] md:text-[11px] overflow-hidden max-h-5 w-full relative">
                        {person.location}
                      </p>
                      <FavoriteControls {...{ userData, person }}>
                        <div className="flex justify-start w-full gap-2 h-[30px]">
                          <FavoriteControls.Heart
                            handleMax={() => setPopupState('heart')}
                          />
                          <FavoriteControls.Favorite
                            handleMax={() => setPopupState('favorite')}
                          />
                        </div>
                      </FavoriteControls>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-wrap w-full m-10 h-max">
              {currentPeople?.map((person) => {
                return (
                  <div
                    key={person.id}
                    className={cn(
                      'w-full h-max border-[1px] m-2',
                      person.billing === 'gold' &&
                        'border-[2px] border-amber-300',
                      person.billing === 'diamond' &&
                        'border-[2px] border-sky-300',
                    )}
                  >
                    <Link to={`/user/${person.id}`}>
                      <img
                        src={imagesUrls[person.id]}
                        alt={`${person.name}'s profile`}
                        className="object-cover object-center w-full cursor-pointer aspect-square"
                      />
                    </Link>

                    {/* name - last seen - age - location - heart - star */}
                    <div className="w-full md:h-[110px] p-4 flex flex-wrap">
                      <div className="flex flex-wrap w-[60%]">
                        <div className="flex flex-wrap w-full text-[12px]">
                          <p className="mr-2 overflow-hidden ">{person.name}</p>
                          <LastOnline className="-mt-1" userId={person.id}>
                            <LastOnline.Indicator className="w-[6px] h-[6px]" />
                            <LastOnline.OnlineStatus />
                          </LastOnline>
                        </div>

                        <div className="flex flex-wrap w-full text-[10px]">
                          <p className="relative mr-2 ">{person.age}</p>
                          <p className="relative">{person.location}</p>
                        </div>
                      </div>

                      <div className="flex h-[40px] flex-wrap w-[40%]">
                        <FavoriteControls {...{ userData, person }}>
                          <div className="flex justify-start w-full gap-2 h-[30px]">
                            <FavoriteControls.Heart />
                            <FavoriteControls.Favorite />
                          </div>
                        </FavoriteControls>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* jumping page controls */}
          <div className="text-[10px] sm:text-[16px] mx-auto w-max flex flex-wrap mt-5">
            <button onClick={() => jumpToPage(1)}>
              <BsChevronDoubleLeft className="relative -bottom-[1px]" />
            </button>

            <button onClick={goToPrevPage} disabled={currentPage === 1}>
              <BsChevronLeft className="relative -bottom-[1px]" />
            </button>

            <div className="w-[110px] text-center relative -bottom-[1px]">
              Page {currentPage} of {totalPages}
            </div>

            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              <BsChevronRight className="relative -bottom-[1px]" />
            </button>

            <button onClick={() => jumpToPage(totalPages)}>
              <BsChevronDoubleRight className="relative -bottom-[1px]" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
