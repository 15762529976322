import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, serverTimestamp } from 'firebase/database';

export const useReceiverOnlineStatus = (params) => {
  const { receiverId, conversationId } = params;

  const [receiverOnlineStatus, setReceiverOnlineStatus] = useState({
    online: false,
    page: 'offline',
    isInConversation: false,
  });

  useEffect(() => {
    if (!receiverId) return;

    const db = getDatabase();
    const onlineStatusRef = ref(db, `users/${receiverId}/onlineStatus`);

    const unsubscribe = onValue(
      onlineStatusRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const onlineStatus = snapshot.val();
          const isInConversation =
            onlineStatus.conversation === conversationId && onlineStatus.online;

          // Update isInConversation based on receiver's current conversation
          setReceiverOnlineStatus({
            ...onlineStatus,
            isInConversation,
          });
        } else {
          setReceiverOnlineStatus({
            online: false,
            page: 'offline',
            isInConversation: false,
            lastOnline: serverTimestamp(),
          }); // Default state if not set
        }
      },
      {
        onlyOnce: false,
      },
    );

    return () => {
      unsubscribe(); // Unsubscribe when the receiverId changes or the component unmounts
    };
  }, [receiverId, conversationId]);

  return receiverOnlineStatus;
};
