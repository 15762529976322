import { useEffect } from 'react';
import {
  getDatabase,
  ref,
  set,
  onDisconnect,
  serverTimestamp,
} from 'firebase/database';
import { useLocation } from 'react-router-dom';
import useUserStore from './useUserStore';

export const useOnlineStatus = () => {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }));
  const isSigningOut = useUserStore((state) => state.isSigningOut);

  const location = useLocation();
  const userId = user?.uid;

  useEffect(() => {
    if (!userId || isSigningOut) return;

    const db = getDatabase();
    const onlineStatusRef = ref(db, `users/${userId}/onlineStatus`);

    const updateOnlineStatus = async () => {
      const isLookingAtMessages = location.pathname.includes('/messages');
      const currentPage = isLookingAtMessages ? 'messages' : 'browsing';
      let conversationId = null;

      if (isLookingAtMessages) {
        // Extract conversationId from the URL
        const pathSegments = location.pathname.split('/');
        const conversationIndex = pathSegments.indexOf('messages') + 1;
        conversationId = pathSegments[conversationIndex];
      }

      await set(onlineStatusRef, {
        online: true,
        page: currentPage,
        conversation: conversationId,
      });
    };

    updateOnlineStatus();

    // Set offline on disconnect
    onDisconnect(onlineStatusRef).set({
      online: false,
      page: 'offline',
      conversation: null,
      lastOnline: serverTimestamp(),
    });
  }, [userId, location, isSigningOut]);
};
