import { useState } from 'react';
import { getDatabase, ref, set, push, get, update } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useHeartedOrFavorited } from './useHeartedOrFavorited';
import useUserStore from '../hooks/useUserStore';

const useOpenConversation = () => {
  const [conversationLoading, setConversationLoading] = useState();
  const { user } = useUserStore((state) => ({ user: state.user }));
  const senderId = user.uid;
  const navigate = useNavigate();
  const determineLikedStatus = useHeartedOrFavorited();

  const openConversation = async (receiverId) => {
    setConversationLoading(true);

    try {
      const db = getDatabase();

      if (!senderId || !receiverId) throw new Error('Invalid user id');

      const isLikedOrFavorited = await determineLikedStatus({
        senderId,
        receiverId,
      });

      if (!isLikedOrFavorited) {
        setConversationLoading(false);
        navigate(`/user/${receiverId}`);
        return;
      }

      // Check if a conversation between these users already exists
      const userConversationsRef = ref(db, `users/${senderId}/conversations`);
      const snapshot = await get(userConversationsRef);

      if (snapshot.exists() && snapshot.val()[receiverId]) {
        const conversationId = snapshot.val()[receiverId];
        return navigate(
          `/messages/${conversationId}/${senderId}/${receiverId}`,
        ); // Exit the function if conversation already exists
      }

      // Generate a unique conversation ID
      const newConversationRef = push(ref(db, 'messages'));
      const conversationId = newConversationRef.key;

      // Create the new conversation object in the 'messages' table
      const newConversation = {
        participants: {
          [senderId]: true,
          [receiverId]: true,
        },
        messages: [],
      };

      // Update the 'messages' table with the new conversation
      await set(newConversationRef, newConversation);

      // Prepare updates for both users in the 'users' table
      const updates = {};
      updates[`users/${senderId}/conversations/${receiverId}`] = conversationId;
      updates[`users/${receiverId}/conversations/${senderId}`] = conversationId;

      // Update the 'users' table
      await update(ref(db), updates);
      navigate(`/messages/${conversationId}/${senderId}/${receiverId}`);
    } catch (error) {
      console.error('Error creating new conversation:', error);
      throw error; // Rethrow the error for handling by the caller
    }

    setConversationLoading(false);
  };

  return { openConversation, conversationLoading };
};

export default useOpenConversation;
