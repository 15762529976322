import axios from 'axios';
import { backendUrl } from './backendUrl';
import { auth } from '../firebase';

export const api = async ({ method, url, ...rest }) => {
  try {
    const token = await auth.currentUser.getIdToken(true);
    const baseUrl = `${backendUrl}${url}`;

    return axios({
      method: method,
      url: baseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...rest,
    });
  } catch (err) {
    console.log('unable to fulfill request', err);
    throw err;
  }
};
