import Loader from './Loader';

const LoadingAuthentication = () => {
  if (window.location.pathname === '/') return null;

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Loader />
    </div>
  );
};

export default LoadingAuthentication;
