import { useState } from 'react';
import { ref, get, getDatabase, set } from 'firebase/database';
import { favoritesLimit, heartsLimit } from '../utils/limits';

const setHearts = async ({ db, personId, user, likesCounter, timeStamp }) => {
  const { likes = [] } = user;
  const userId = user.id;

  await set(ref(db, `users/${userId}/heartsToday`), {
    likesCounter,
    firstHeartTimestamp: timeStamp,
  });

  const personRef = ref(db, `users/${personId}/likedBy`);
  const personLikedBy = await get(personRef);
  const personLikedByVal = personLikedBy.exists() ? personLikedBy.val() : [];

  const newLikedBy = [...personLikedByVal, userId];
  await set(ref(db, `users/${personId}/likedBy`), newLikedBy);

  await set(ref(db, `users/${userId}/likes`), [...likes, personId]);
};

export const useHeart = () => {
  const [heartLoading, setHeartLoading] = useState(false);
  const [heartError, setHeartError] = useState(null);

  const handleHeart = async ({
    userId,
    personId,
    alreadyHearted,
    handleMax,
  }) => {
    if (!userId) throw new Error('Invalid user id');
    if (alreadyHearted) {
      alert('Already hearted user');
      return;
    }

    setHeartLoading(true);

    try {
      const db = getDatabase();

      const userRef = ref(db, `users/${userId}`);
      const userSnapshot = await get(userRef);
      const user = userSnapshot.exists() ? userSnapshot.val() : {};

      const { heartsToday = {}, billing } = user;
      const { likesCounter = 0, firstHeartTimestamp = null } = heartsToday;
      const maxHearts = heartsLimit[billing];

      const hasUnlimitedHearts = maxHearts === -1;
      let newLikesCounter = likesCounter;

      if (!hasUnlimitedHearts) newLikesCounter += 1;

      const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000;
      const lastHeartedOneDayAgo = firstHeartTimestamp < oneDayAgo;
      const resetHearts = lastHeartedOneDayAgo || !firstHeartTimestamp;

      if (resetHearts && !hasUnlimitedHearts) {
        newLikesCounter = 1;

        await setHearts({
          db,
          personId,
          user,
          likesCounter: newLikesCounter,
          timeStamp: Date.now(),
        });

        return;
      }

      const withinLimit = newLikesCounter < maxHearts + 1;
      const canHeart = hasUnlimitedHearts || withinLimit;

      if (!canHeart) {
        handleMax();
        return;
      }

      await setHearts({
        db,
        personId,
        user,
        likesCounter: newLikesCounter,
        timeStamp: firstHeartTimestamp || Date.now(),
      });

      setHeartError(false);
    } catch (error) {
      console.log('error', error);
      setHeartError(true);
    } finally {
      setHeartLoading(false);
    }
  };

  return { handleHeart, heartLoading, heartError };
};

const setFavorites = async ({
  db,
  personId,
  user,
  favoritesCounter,
  timeStamp,
}) => {
  const { favorites = [] } = user;
  const userId = user.id;

  await set(ref(db, `users/${userId}/favoritesToday`), {
    favoritesCounter,
    firstFavoritesTimestamp: timeStamp,
  });

  const personRef = ref(db, `users/${personId}/favoritedBy`);
  const personFavoritedBy = await get(personRef);
  const personFavoritedByVal = personFavoritedBy.exists()
    ? personFavoritedBy.val()
    : [];

  const newFavoritedBy = [...personFavoritedByVal, userId];
  await set(ref(db, `users/${personId}/favoritedBy`), newFavoritedBy);

  await set(ref(db, `users/${userId}/favorites`), [...favorites, personId]);
};

export const useFavorite = () => {
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [favoriteError, setFavoriteError] = useState(null);

  const handleFavorite = async ({
    userId,
    personId,
    alreadyFavorited,
    handleMax,
  }) => {
    if (!userId) throw new Error('Invalid user id');
    if (alreadyFavorited) {
      alert('Already favorited user');
      return;
    }

    setFavoriteLoading(true);

    try {
      const db = getDatabase();

      const userRef = ref(db, `users/${userId}`);
      const userSnapshot = await get(userRef);
      const user = userSnapshot.exists() ? userSnapshot.val() : {};

      const { favoritesToday = {}, billing } = user;
      const { favoritesCounter = 0, firstFavoritesTimestamp = null } =
        favoritesToday;
      const maxFavorites = favoritesLimit[billing];

      const hasUnlimitedFavorites = maxFavorites === -1;
      let newFavoritesCounter = favoritesCounter;

      if (!hasUnlimitedFavorites)
        newFavoritesCounter = newFavoritesCounter += 1;

      const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000;
      const lastFavoriteedOneDayAgo = firstFavoritesTimestamp < oneDayAgo;
      const resetFavorites =
        lastFavoriteedOneDayAgo || !firstFavoritesTimestamp;

      if (resetFavorites && !hasUnlimitedFavorites) {
        newFavoritesCounter = 1;

        await setFavorites({
          db,
          personId,
          user,
          favoritesCounter: newFavoritesCounter,
          timeStamp: Date.now(),
        });

        return;
      }

      const withinLimit = newFavoritesCounter < maxFavorites + 1;
      const canFavorite = hasUnlimitedFavorites || withinLimit;

      if (!canFavorite) {
        handleMax();
        return;
      }

      await setFavorites({
        db,
        personId,
        user,
        favoritesCounter: newFavoritesCounter,
        timeStamp: firstFavoritesTimestamp || Date.now(),
      });

      setFavoriteError(false);
    } catch (error) {
      console.log('error', error);
      setFavoriteError(true);
    } finally {
      setFavoriteLoading(false);
    }
  };

  return { handleFavorite, favoriteLoading, favoriteError };
};
