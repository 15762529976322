import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import { FaAngleDown } from 'react-icons/fa6';
import { cn } from '../utils/cn';

function UserFilter({
  gender,
  handleGenderChange,
  userUID,
  age,
  setAge,
  children,
  handleChildrenChange,
  location,
  handleLocationChange,
  ethnicity,
  handleEthnicityChange,
  relationship,
  handleRelationshipChange,
  religion,
  handleReligionChange,
  filteredPeople,
}) {
  const [dropdown, setDropdown] = useState(false);

  return (
    <div className="flex flex-wrap w-full mx-10 h-min">
      <p
        className="w-full text-[16px] md:text-[18px] m-2 flex flex-wrap cursor-pointer select-none"
        onClick={() => setDropdown(!dropdown)}
      >
        Filter{' '}
        <span>
          <FaAngleDown
            className={cn(' mt-1 ml-3 duration-300', dropdown && 'rotate-180 ')}
          />
        </span>
      </p>
      <div className={cn(dropdown ? 'hidden' : 'flex flex-wrap')}>
        <div className="w-min h-min bg-[#f1f1f1] text-[14px] md:text-[14px] p-3 m-1 flex-column hidden">
          <p>gender</p> {userUID}
          <select
            className="w-min h-min ml-3 bg-white border-[1px]"
            value={gender}
            onChange={handleGenderChange}
            disabled={!!gender} // <-- Disable select dropdown if gender has a value
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="w-min h-min bg-[#f1f1f1] text-[14px] md:text-[14px] p-3 m-1 flex flex-column">
          <p className="w-[95px]">
            age {age.min} - {age.max}
          </p>
          <label className="flex items-center ml-3">
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="thumb"
              trackClassName="track"
              defaultValue={[age.min, age.max]}
              min={18}
              max={100}
              step={1}
              withBars={true}
              onChange={(values) => {
                const [newMin, newMax] = values;
                setAge({ min: newMin, max: newMax });
              }}
            />
          </label>
        </div>
        <div className="w-max h-min bg-[#f1f1f1] text-[14px] md:text-[14px] p-3 m-1 flex flex-column">
          <p>has children</p>
          <select
            className="w-min h-min ml-3 bg-white border-[1px]"
            value={children}
            onChange={handleChildrenChange}
          >
            <option value="">Select Children</option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
        <div className="w-min h-min bg-[#f1f1f1] text-[14px] md:text-[14px] p-3 m-1 flex flex-column">
          <p>location</p>
          <label className="flex items-center ml-3">
            <input
              type="text"
              placeholder="Enter Location"
              value={location}
              onChange={handleLocationChange}
              className="w-min h-min ml-3 bg-white border-[1px] pl-2"
            />
          </label>
        </div>
        <div className="w-min h-min bg-[#f1f1f1] text-[14px] md:text-[14px] p-3 m-1 flex flex-column">
          <p>ethnicity</p>
          <select
            className="w-min h-min ml-3 bg-white border-[1px]"
            value={ethnicity}
            onChange={handleEthnicityChange}
          >
            <option value="">Select Ethnicity</option>
            <option value="Black / African Descent">
              Black / African Descent
            </option>
            <option value="Asian">Asian</option>
            <option value="Hispanic / Latin">Hispanic / Latin</option>
            <option value="Middle Eastern">Middle Eastern</option>
            <option value="Mixed">Mixed</option>
            <option value="White / Caucasian">White / Caucasian</option>
          </select>
        </div>
        <div className="w-min h-min bg-[#f1f1f1] text-[14px] md:text-[14px] p-3 m-1 flex flex-column">
          <p>seeking</p>
          <select
            className="w-min h-min ml-3 bg-white border-[1px]"
            value={relationship}
            onChange={handleRelationshipChange}
          >
            <option value="">Select Seeking</option>
            <option value="Friendship">Friendship </option>
            <option value="Short term dating">Short term dating</option>
            <option value="Long term dating">Long term dating</option>
            <option value="Casual dating">Casual dating</option>
          </select>
        </div>
        <div className="w-min h-min bg-[#f1f1f1] text-[14px] md:text-[14px] p-3 m-1 flex flex-column">
          <p>religion</p>
          <select
            className="w-min h-min ml-3 bg-white border-[1px]"
            value={religion}
            onChange={handleReligionChange}
          >
            <option value="">Select Religion</option>
            <option value="Buddhist">Buddhist</option>
            <option value="Christian">Christian</option>
            <option value="Christian - Catholic">Christian - Catholic</option>
            <option value="Hindu">Hindu</option>
            <option value="Jewish">Jewish</option>
            <option value="Muslim">Muslim</option>
            <option value="Not religious">Not religious</option>
            <option value="Sikh">Sikh</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <p className="w-full m-2 text-[#767676] relative top-3">
          {filteredPeople.length} members available
        </p>
      </div>
    </div>
  );
}

export default UserFilter;
