export const getLastOnline = (lastOnlineTimestamp) => {
  if (!lastOnlineTimestamp) return '';

  const now = Date.now();
  const lastOnline = new Date(lastOnlineTimestamp).getTime(); // Convert to milliseconds
  const diffInMilliseconds = now - lastOnline;

  const minutes = Math.floor(diffInMilliseconds / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) return 'Last seen recently';
  if (minutes < 60) return `Last seen ${minutes} minutes ago`;
  if (hours < 24) return `Last seen ${hours} hours ago`;
  return `Last seen ${days} days ago`;
};
