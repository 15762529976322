import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Admin from './pages/Admin';
import Login from './pages/Login';
import Setup from './pages/Setup';
import Signup from './pages/Signup';
import ErrorPage from './pages/ErrorPage';
import Dashboard from './pages/Dashboard';
import Lists from './pages/Lists';
import Notifications from './pages/Notifications';
import EditProfile from './pages/EditProfile';
import BuyMembership from './pages/BuyMembership';
import Landing from './pages/Landing';
import Person from './pages/Person';
import Checkout from './pages/Checkout';
import Messages from './pages/Messages';
import useFirebaseAuth from './hooks/useFirebaseAuth';
import useUserStore from './hooks/useUserStore';
import AuthorizedPage from './components/AuthorizedRoute';
import LoadingAuthentication from './components/LoadingAuthentication';
import OnlineStatusUpdater from './pages/OnlineStatusUpdater';
import Settings from './pages/Settings';

function App() {
  useFirebaseAuth();
  const { authState } = useUserStore((state) => ({
    authState: state.authState,
  }));

  const loadingAuthentication = authState === 'loading';
  let resizeTimeout;

  window.addEventListener('resize', () => {
    document.body.classList.add('no-animation');

    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      document.body.classList.remove('no-animation');
    }, 100);
  });

  return loadingAuthentication ? (
    <LoadingAuthentication />
  ) : (
    <>
      <Router>
        <OnlineStatusUpdater />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route
            path="/dashboard"
            element={
              <AuthorizedPage>
                <Dashboard />
              </AuthorizedPage>
            }
          />
          <Route
            path="/lists"
            element={
              <AuthorizedPage>
                <Lists />
              </AuthorizedPage>
            }
          />
          <Route
            path="/notification"
            element={
              <AuthorizedPage>
                <Notifications />
              </AuthorizedPage>
            }
          />
          <Route
            path={`/user/:userId`}
            element={
              <AuthorizedPage>
                <Person />
              </AuthorizedPage>
            }
          />
          <Route
            path={`/messages/:conversationId/:senderId/:receiverId`}
            element={
              <AuthorizedPage>
                <Messages />
              </AuthorizedPage>
            }
          />
          <Route
            path="/editProfile"
            element={
              <AuthorizedPage>
                <EditProfile />
              </AuthorizedPage>
            }
          />
          <Route
            path="/buyMembership"
            element={
              <AuthorizedPage>
                <BuyMembership />
              </AuthorizedPage>
            }
          />
          <Route
            path="/checkout/:tier"
            element={
              <AuthorizedPage>
                <Checkout />
              </AuthorizedPage>
            }
          />
          <Route
            path="/settings"
            element={
              <AuthorizedPage>
                <Settings />
              </AuthorizedPage>
            }
          />
          <Route
            path="/admin/123"
            element={
              <AuthorizedPage>
                <Admin />
              </AuthorizedPage>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/setup" element={<Setup />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
