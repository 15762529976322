import { favoritesLimit, heartsLimit } from '../utils/limits';
import { AiFillGold, AiFillHeart, AiFillStar } from 'react-icons/ai';
import { RiVipDiamondFill } from 'react-icons/ri';
import { BiSolidWinkSmile } from 'react-icons/bi';
import { Countdown } from './Countdown';
import { cn } from '../utils/cn';

export const DashboardStats = ({ userData }) => {
  const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000;
  const membershipStatus = userData?.billing || 'free';

  const calculateStats = (counter, timestamp, limit) => {
    const lastActionOneDayAgo = timestamp < oneDayAgo;
    const todayCount = lastActionOneDayAgo ? 0 : counter;
    const unlimited = limit === -1;
    const maxed = todayCount >= limit && !unlimited;
    const text = unlimited ? 'unlimited' : `${todayCount}/${limit}`;
    return { timestamp, maxed, text };
  };

  const likes = calculateStats(
    userData?.heartsToday?.likesCounter || 0,
    userData?.heartsToday?.firstHeartTimestamp || 0,
    heartsLimit[membershipStatus],
  );

  const favorites = calculateStats(
    userData?.favoritesToday?.favoritesCounter || 0,
    userData?.favoritesToday?.firstFavoritesTimestamp || 0,
    favoritesLimit[membershipStatus],
  );

  const membershipTiers = {
    free: {
      icon: <BiSolidWinkSmile className="w-8 h-8 text-pink-400" />,
      color: 'text-pink-400',
      text: 'text-pink-700',
      background: 'bg-pink-100',
    },
    gold: {
      icon: <AiFillGold className="w-8 h-8 text-amber-500" />,
      color: 'text-amber-500',
      text: 'text-amber-700',
      background: 'bg-amber-100',
    },
    diamond: {
      icon: <RiVipDiamondFill className="w-8 h-8 text-sky-400" />,
      color: 'text-sky-400',
      text: 'text-sky-700',
      background: 'bg-sky-100',
    },
  };

  const heartClasses = cn('text-red-500 w-8 h-8', likes.maxed && 'opacity-50');
  const favoriteClasses = cn(
    'text-yellow-500 w-8 h-8',
    favorites.maxed && 'opacity-50',
  );

  const moduleClasses =
    'flex items-center gap-2 px-4 py-2 rounded-md w-full lg:w-auto';

  if (!userData) return null;

  return (
    <div className="p-5 flex flex-col lg:flex-row gap-2 w-full flex-wrap">
      {/* membership */}
      <div
        className={cn(
          moduleClasses,
          membershipTiers?.[membershipStatus]?.text,
          membershipTiers?.[membershipStatus]?.background,
        )}
      >
        {membershipTiers?.[membershipStatus]?.icon}
        <div>
          Your membership status is{' '}
          <strong className={`${membershipTiers?.[membershipStatus]?.color}`}>
            {membershipStatus}
          </strong>
        </div>
      </div>

      {/* likes */}
      <div className={cn(moduleClasses, 'bg-red-100 text-red-700')}>
        <AiFillHeart className={heartClasses} />
        <div className={cn(likes.maxed && 'opacity-50')}>
          <strong>{likes.text}</strong> likes today
        </div>
        <div>
          {likes.maxed && (
            <div>
              (Renewing in <Countdown timestamp={likes.timestamp} />)
            </div>
          )}
        </div>
      </div>

      {/* favorites */}
      <div className={cn(moduleClasses, 'bg-yellow-100 text-yellow-700')}>
        <AiFillStar className={favoriteClasses} />
        <div className={cn(favorites.maxed && 'opacity-50')}>
          <strong>{favorites.text}</strong> favorites today
        </div>
        {favorites.maxed && (
          <div>
            (Renewing in <Countdown timestamp={favorites.timestamp} />)
          </div>
        )}
      </div>
    </div>
  );
};
